

// Admin Module Routes:-
import screens from '@/plugins/screens.json';
// Home
import AdminHome from "@/views/Admin/AdminHome";
// Users
import UsersEntity from "@/views/Admin/Users/UsersEntity";
import UsersControl from "@/views/Admin/Users/UsersControl";
// Projects
import ProjectsEntity from "@/views/Admin/Projects/ProjectsEntity";
import ProjectsControl from "@/views/Admin/Projects/ProjectsControl";
// Services
import ServicesEntity from "@/views/Admin/Services/ServicesEntity";
import ServicesControl from "@/views/Admin/Services/ServicesControl";
// Blog&News
import BlogsNewsEntity from "@/views/Admin/Blog&News/Blog&NewsEntity";
import BlogsNewsControl from "@/views/Admin/Blog&News/Blog&NewsControl";
// Partners
import PartnersEntity from "@/views/Admin/Partners/PartnersEntity";
import PartnersControl from "@/views/Admin/Partners/PartnersControl";
// Contact Request
import ContactRequestsEntity from "@/views/Admin/ContactRequests/ContactRequestsEntity";
// Website
import WebsiteSettingsControl from "@/views/Admin/WebsiteSettings/WebsiteSettingsControl";
// Landing Section
import LandingSectionControl from "@/views/Admin/LandingSection/LandingSectionControl";
// About US
import AboutUSControl from "@/views/Admin/AboutUS/AboutUSControl";
// Portfolio
import PortfolioEntity from "@/views/Admin/Portfolio/PortfolioEntity";
import PortfolioControl from "@/views/Admin/Portfolio/PortfolioControl";
// Certificates
import CertificatesEntity from "@/views/Admin/Certificates/CertificatesEntity";
import CertificatesControl from "@/views/Admin/Certificates/CertificatesControl";

const system_screens = screens.filter(screen => 'screen_code' in screen) || []
export const AdminModuleChildrens = [
  {
    path: "/",
    name: "AdminHome",
    component: AdminHome,
    meta: system_screens[0],
  },
  // Users
  {
    path: "/users",
    name: "UsersEntity",
    component: UsersEntity,
    meta: system_screens[1],
  },
  {
    path: "/users-control",
    name: "UsersControl",
    component: UsersControl,
    meta: system_screens[1],
  },
  {
    path: "/users-control/:id",
    name: "UsersControlEdit",
    component: UsersControl,
    meta: system_screens[1],
  },
  // Projects
  {
    path: "/projects",
    name: "ProjectsEntity",
    component: ProjectsEntity,
    meta: system_screens[2],
  },
  {
    path: "/projects-control",
    name: "ProjectsControl",
    component: ProjectsControl,
    meta: system_screens[2],
  },
  {
    path: "/projects-control/:id",
    name: "ProjectsControlEdit",
    component: ProjectsControl,
    meta: system_screens[2],
  },
  // Services
  {
    path: "/services",
    name: "ServicesEntity",
    component: ServicesEntity,
    meta: system_screens[3],
  },
  {
    path: "/services-control",
    name: "ServicesControl",
    component: ServicesControl,
    meta: system_screens[3],
  },
  {
    path: "/services-control/:id",
    name: "ServicesControlEdit",
    component: ServicesControl,
    meta: system_screens[3],
  },
  // Blogs & News
  {
    path: "/blogs-news",
    name: "BlogsNewsEntity",
    component: BlogsNewsEntity,
    meta: system_screens[4],
  },
  {
    path: "/blogs-news-control",
    name: "BlogsNewsControl",
    component: BlogsNewsControl,
    meta: system_screens[4],
  },
  {
    path: "/blogs-news-control/:id",
    name: "BlogsNewsControlEdit",
    component: BlogsNewsControl,
    meta: system_screens[4],
  },
  // Partners
  {
    path: "/partners",
    name: "PartnersEntity",
    component: PartnersEntity,
    meta: system_screens[5],
  },
  {
    path: "/partners-control",
    name: "PartnersControl",
    component: PartnersControl,
    meta: system_screens[5],
  },
  {
    path: "/partners-control/:id",
    name: "PartnersControlEdit",
    component: PartnersControl,
    meta: system_screens[5],
  },
  // Contact Requests
  {
    path: "/contact",
    name: "ContactRequestsEntity",
    component: ContactRequestsEntity,
    meta: system_screens[6],
  },
  // Website Settings
  {
    path: "/website-settings",
    name: "WebsiteSettingsControl",
    component: WebsiteSettingsControl,
    meta: system_screens[7],
  },
  // landing Section 
  {
    path: "/landing-section",
    name: "LandingSectionControl",
    component: LandingSectionControl,
    meta: system_screens[8],
  },
  // About GEICO
  {
    path: "/about-us",
    name: "AboutUSControl",
    component: AboutUSControl,
    meta: system_screens[9],
  },
  // Portfolio
  {
    path: "/portfolio",
    name: "PortfolioEntity",
    component: PortfolioEntity,
    meta: system_screens[10],
  },
  {
    path: "/portfolio-control",
    name: "PortfolioControl",
    component: PortfolioControl,
    meta: system_screens[10],
  },
  {
    path: "/portfolio-control/:id",
    name: "PortfolioControlEdit",
    component: PortfolioControl,
    meta: system_screens[10],
  },
  // Certificates
  {
    path: "/certificates",
    name: "CertificatesEntity",
    component: CertificatesEntity,
    meta: system_screens[11],
  },
  {
    path: "/certificates-control",
    name: "CertificatesControl",
    component: CertificatesControl,
    meta: system_screens[11],
  },
  {
    path: "/certificates-control/:id",
    name: "CertificatesControlEdit",
    component: CertificatesControl,
    meta: system_screens[11],
  },
  
];
