<template>
   <v-row justify="center">
      <v-dialog v-model="dialog" overlay-opacity="0.75" persistent max-width="450">
         <v-card relative class="backgroundW">
            <v-form ref="form" v-model="valid" lazy-validation>
               <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
                  color="grey lighten-1" text @click="closeDialog">
                  <v-icon color="grey lighten-1">mdi-close</v-icon>
               </v-btn>
               <v-card-title class="text-h6 font-weight-bold">
                  <v-icon size="28" color="success">mdi-shield-key-outline</v-icon>
                  <h5 class="ms-3 text-h6 font-weight-bold success--text">
                     <span>{{ $t('change password') }}</span>
                  </h5>
               </v-card-title>

               <v-col cols="12" sm="12">
                  <v-col cols="12" sm="12">
                     <label>{{ $t('current password') }}*</label>
                     <v-text-field hide-details="auto" class="my-0 rounded-lg" filled required color="primary"
                        :rules="passwordRule" v-model="currentPassword" dense rounded
                        :append-icon="showCrrPass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                        :type="showCrrPass ? 'text' : 'password'"
                        @click:append="showCrrPass = !showCrrPass"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                     <label>{{ $t('new password') }}*</label>
                     <v-text-field hide-details="auto" class="my-0 rounded-lg" filled required color="primary"
                        :rules="passwordRule" v-model="newPassword" dense rounded
                        :append-icon="showNewPass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                        :type="showNewPass ? 'text' : 'password'"
                        @click:append="showNewPass = !showNewPass"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                     <label>{{ $t('confirm password') }}*</label>
                     <v-text-field hide-details="auto" class="my-0 rounded-lg" filled required color="primary"
                        :rules="confirmPasswordRule" v-model="confirmPassword" dense rounded
                        :append-icon="showConfPass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                        :type="showConfPass ? 'text' : 'password'"
                        @click:append="showConfPass = !showConfPass"></v-text-field>
                  </v-col>
               </v-col>

               <v-col cols="12" class="py-4">
                  <v-row justify="end">
                     <v-col cols="auto">
                        <v-btn outlined @click="closeDialog" class="shadow" color="error"
                           style="width: 100%; text-align: start">{{ $t('close') }}</v-btn>
                     </v-col>

                     <v-col cols="auto">
                        <v-btn color="primary" :disabled="!valid" @click="changePassword" class="shadow"
                           :loading="isLoading == true" style="width: 100%; text-align: start">{{ $t('save') }}</v-btn>
                     </v-col>
                  </v-row>
               </v-col>
            </v-form>
         </v-card>
      </v-dialog>
   </v-row>
</template>



<style scoped lang="scss">
.flip-list-move {
   transition: transform 0.5s;
}

.no-move {
   transition: transform 0s;
}
</style>



<script>

export default {
   name: "ChangePasswordModal",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
   },
   computed: {
      isRTL() {
         return this.$store.state.Settings.isRTL;
      },
   },
   components: {

   },
   data() {
      return {
         isLoading: false,
         currentPassword: null,
         newPassword: null,
         confirmPassword: null,
         passwordRule: [],
         confirmPasswordRule: [],
         showCrrPass: false,
         showNewPass: false,
         showConfPass: false,
         show: false,
         valid: false,
      };
   },
   methods: {
      closeDialog() {
         this.dialog = false
         this.backValueMethod(false);
         this.currentPassword = null
         this.confirmPassword = null
         this.newPassword = null
      },
      changePassword() {
         var requestBody = {
            old_password: this.currentPassword,
            new_password: this.confirmPassword,
            new_password2: this.newPassword,
         }
         this.isLoading = true;
         this.$api.POST_METHOD(`change_password`, requestBody).then((response) => {
            console.log(response);
            if (response.check == true) {
               this.isLoading = false;
               this.backValueMethod(false);
               this.currentPassword = null
               this.confirmPassword = null
               this.newPassword = null
               this.$api.LOGOUT()
            } if (response.check == false) {
               this.isLoading = false;
            }
         })


      },

   },
   mounted() {
      this.passwordRule = [
         v => !!v || this.$t('Password is required'),
         v => (v && v.length >= 5) || this.$t('Password must be greater than 5 characters'),
      ]
      this.confirmPasswordRule = [
         v => !!v || this.$t('Password is required'),
         v => (v && v == this.newPassword) || this.$t('Password not match'),
      ]
   },
};
</script>
