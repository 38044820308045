<template>
   <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="450">
         <v-card relative class="backgroundW">
            <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
               color="grey lighten-1" text @click="closeDialog">
               <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>
            <v-card-title class="text-h6 font-weight-bold">
               <v-icon style="background-color: #2D3E50  ;" small class="rounded-pill pa-1 mx-2"
                  color="white">mdi-sort-numeric-ascending</v-icon>
               <h5>
                  <span>{{ $t('Rearrangement') }}</span>
               </h5>
            </v-card-title>
            <v-col v-if="isLoading == true">
               <v-progress-circular :size="70" :width="5" style="margin: auto; " class="d-block" color="#2D3E50"
                  indeterminate />
            </v-col>
            <v-col cols="12" v-if="step == 2 && isLoading == false">
               <draggable :list="data" :disabled="!enabled" class ghost-class="light" @change="checkMove"
                  @start="dragging = true" @end="dragging = false" v-model="data">
                  <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                     <div style="cursor :move; border-bottom: 1px solid #eee" v-for="(element) in data" :key="element.id">
                        <div class="d-flex pa-3 align-center">
                           <p class="col-1 text-center pa-0 my-0">{{ element.id }}</p>
                           <v-divider class="mx-3" vertical></v-divider>
                           <p class="my-0">{{ element[`title_${$i18n.locale}`] }}</p>
                        </div>
                     </div>
                  </transition-group>
               </draggable>
            </v-col>
            <v-col cols="12" class="py-4">
               <v-row justify="end">
                  <v-col cols="auto">
                     <v-btn outlined @click="closeDialog" class="shadow" color="error"
                        style="width: 100%; text-align: start">{{ $t('close') }}</v-btn>
                  </v-col>
                  <v-col cols="auto" v-if="step == 1">
                     <v-btn color="primary" :disabled="!mainMenu" @click="step = 2" class="shadow white--text"
                        style="width: 100%; text-align: start">{{ $t('next') }}</v-btn>
                  </v-col>
                  <v-col cols="auto" v-if="step == 2">
                     <v-btn color="#8F8F8F" @click="returnData" :loading="isLoading" class="shadow white--text"
                        style="width: 100%; text-align: start">{{ $t('save') }}</v-btn>
                  </v-col>
               </v-row>
            </v-col>
         </v-card>
      </v-dialog>
   </v-row>
</template>






<script>

import draggable from "vuedraggable";
export default {
   name: "RearrangementDialog",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
      isLoading: { default: false },

   },
   computed: {

   },
   components: {
      draggable
   },
   data() {
      return {

         data: [],
         step: 2,
         dragging: false,
         enabled: true,
         mainMenu: null,
         subMenus: [],
         newOrder: [],
         oldOrder: [],
         drag: ''
      };
   },
   methods: {
      getData() {

         this.isLoading = true
         this.$api.GET_METHOD(`Portfolio/${this.$i18n.locale}`).then((response) => {
            this.isLoading = false
            if (response.status) {
               this.data = response.data
            }
         })

      },


      closeDialog() {
         this.dialog = false
         this.backValueMethod(this.oldOrder, false);
         this.step = 2
         this.mainMenu = null
         this.subMenu = null
      },
      returnData() {

         this.backValueMethod(this.newOrder, true);
         this.step = 2
         this.subMenu = null
         this.mainMenu = null
      },
      checkMove() {
         this.newOrder = []
         this.data.forEach((element, index) => {
            return this.newOrder.push({ 'id': element.id, order: index + 1 })
         });
         console.log("newOrder ", this.newOrder);
      }
   },
   mounted() {
      this.getData()
      this.oldOrder = this.data
   }
};
</script>


<style scoped lang="scss">
.flip-list-move {
   transition: transform 0.5s;
}

.no-move {
   transition: transform 0s;
}
</style>