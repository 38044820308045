export const ControlMixins = {
  methods: {
    pageMainData() {
      this.pageData.screen_code = this.$store.getters.activeScreen.screen_code || null;
      this.pageData.category = this.$store.getters.activeScreen.category_name || null;
      this.pageData.entityName = this.$store.state.activeScreen.name || null;
      this.pageData.controlName = this.$store.state.activeScreen.singular || null;
      this.pageData.endpoint = this.$store.state.activeScreen.endpoint || this.pageData.endpoint;
      this.pageData.show_endpoint = this.$store.state.activeScreen.show_endpoint || this.pageData.endpoint;
      this.pageData.multi_part = this.$store.state.activeScreen.multi_part;
      this.pageData.tableHeader = this.tableHeader || [];
      this.pageData.url = this.$store.getters.activeScreen.url || null;
    },
    getData() {
      this.pageData.isLoading = false;
      if (this.$route.params.id) {
        this.pageData.isEdit = true;
        this.pageData.editIsLoading = true;
        this.pageData.isLoading = true;
        this.$api.GET_METHOD(`${this.pageData.show_endpoint || this.pageData.endpoint}/${this.$route.params.id}`).then((response) => {
          this.pageData.editIsLoading = false;
          this.pageData.isLoading = false;
          if (response.status) {
            console.log("response", response);
            this.form = response.data;
            // this.$refs.form.validate();
          }
        })
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        this.pageData.isLoading = true;
        if (this.pageData.isEdit == true) {
          delete this.form.isActive;
          delete this.form.createdAt;
          delete this.form.createdBy;
          delete this.form.updatedAt;
          delete this.form.updatedBy;
          this.$api[this.pageData.multi_part ? 'PUT_METHOD_MULTIPART' : 'PUT_METHOD'](`${this.pageData.endpoint}/${this.$route.params.id || 1}`, this.form).then((response) => {
            this.pageData.isLoading = false;
            if (response.status) {
              if (this.$router.currentRoute.fullPath !== this.pageData.url)
                this.$router.push(this.pageData.url)
            }
          })
        }
        else {
          this.$api[this.pageData.multi_part ? 'POST_METHOD_MULTIPART' : 'POST_METHOD'](`${this.pageData.endpoint || 1}`, this.form).then((response) => {
            this.pageData.isLoading = false;
            if (response.status) {
              if (this.$router.currentRoute.fullPath !== this.pageData.url)
                this.$router.push(this.pageData.url)
            }
          })
        }
      }
    },
    deleteMethod(RESOURCE_NAME, ID, STATUS) {
      this.pageData.isLoading = false
      if (RESOURCE_NAME && ID && STATUS) {
        this.$api.DELETE_METHOD(`${this.pageData.endpoint}/${this.form.id}`).then((response) => {
          this.pageData.isLoading = false
          if (response.status) {
            this.$router.push(this.pageData.url)
          }
        });
      } else {
        this.pageData.isLoading = false;
      }
    },
  },
  watch: {
    $route() {
      this.getData();
    }
  },
};
