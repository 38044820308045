
<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pa-7">
               <v-row align="center">

                  <v-col cols="12" md="6">
                     <label for="bgColor" class="w-100">{{ $t('section background color') }}</label>
                     <input type="color" class="w-100" name="bgColor" v-model="form.background_color" id="bgColor" />
                  </v-col>

                  <v-col cols="12" md="6">
                     <label for="fontColor" class="w-100">{{ $t('section font color') }}</label>
                     <input type="color" class="w-100" name="fontColor" v-model="form.font_color" id="bgColor" />
                  </v-col>

                  <GenericInput type="text" inputDesign="inline_label" :value="form.title_en"
                     @input="form.title_en = $event" label="section title" :required="false" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]" />

                  <GenericInput dir="rtl" inputDesign="inline_label" type="text" :value="form.title_ar"
                     @input="form.title_ar = $event" label=" عنوان القسم" :required="false" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]" />


                  <GenericInput type="file" paddingY="py-5" inputDesign="inline_label" :value="form.image_en"
                     @input="form.image_en = $event" label="Section Image" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="file" dir="rtl"  paddingY="py-5" inputDesign="inline_label" :value="form.image_ar"
                     @input="form.image_ar = $event" label="صوره القسم" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="texteditor" :value="form.description_en" @input="form.description_en = $event"
                     label="Section details" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput dir="rtl" type="texteditor" :value="form.description_ar"
                     @input="form.description_ar = $event" label="تفاصيل القسم" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />
               </v-row>

            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import { ControlMixins } from '@/mixins/ControlMixins';
export default {
   name: "PortfolioControl",
   mixins: [ControlMixins],
   data: () => ({
      pageData: {
         screen_code: "01-011",
         endpoint: 'Portfolio',
         url: null,
         controlRoute: "/portfolio-control/",
         entityName: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      form: {
         title_ar: '',
         title_en: '',
         background_color: '#FFFFFF',
         font_color: '#222222',
         description_ar: '',
         description_en: '',
         image_en: '',
         image_ar: '',
      },
   }),
   components: {

   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.getData();

   },
   methods: {

   },
};
</script>
