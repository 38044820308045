<template>

  <div>  
    <router-view></router-view>
    <RequestLoading />
    <UserAlert />
  </div>
  
</template>


<script>
import UserAlert from '@/components/ui/UserAlert.vue';
import RequestLoading from '@/components/ui/RequestLoading.vue';
export default {
  name: "Auth",
  components: { RequestLoading, UserAlert },
  data: () => ({}),
};
</script>
