
<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow overflow-hidden">
               <v-tabs v-model="tab">
                  <v-tab class="text-capitalize">{{ $t('website basic data') }}</v-tab>
                  <v-tab class="text-capitalize">{{ $t('search engine optimization') }} (SEO)</v-tab>
               </v-tabs>
               <v-tabs-items v-model="tab">

                  <v-tab-item class="ma-5">
                     <v-row align="center">
                        <GenericInput type="file" inputDesign="inline_label" :value="form.primary_logo"
                           @input="form.primary_logo = $event" label="primary logo" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="file" inputDesign="inline_label" :value="form.secondary_logo"
                           @input="form.secondary_logo = $event" label="secondary logo" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="mobile" name="mobile" inputDesign="inline_label" :value="form.mobile"
                           @input="form.mobile = $event" label="mobile" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="mobile" name="fax" inputDesign="inline_label" :value="form.fax" @input="form.fax = $event"
                           label="fax" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="email" inputDesign="inline_label" :value="form.email"
                           @input="form.email = $event" label="email" :required="false" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]" />

                        <GenericInput type="text" inputDesign="inline_label" :value="form.facebook"
                           :validation_type="$global.urlRule" @input="form.facebook = $event" label="facebook"
                           :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="text" inputDesign="inline_label" :value="form.youtube"
                           :validation_type="$global.urlRule" @input="form.youtube = $event" label="youtube"
                           :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <!-- <GenericInput type="text" inputDesign="inline_label" :value="form.whatsApp"
                           @input="form.whatsApp = $event" label="whatsApp" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" /> -->

                        <GenericInput type="text" :validation_type="$global.urlRule" inputDesign="inline_label" :value="form.instagram"
                           @input="form.instagram = $event" label="instagram" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="text" :validation_type="$global.urlRule" inputDesign="inline_label" :value="form.twitter"
                           @input="form.twitter = $event" label="twitter" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="text" :validation_type="$global.urlRule" inputDesign="inline_label" :value="form.linkedIn"
                           @input="form.linkedIn = $event" label="linkedIn" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="textarea" inputDesign="inline_label" :value="form.address_en"
                           @input="form.address_en = $event" label="address" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="textarea" inputDesign="inline_label" :value="form.address_ar"
                           @input="form.address_ar = $event" label="العنوان" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />


                     </v-row>
                  </v-tab-item>
                  <v-tab-item class="ma-5">
                     <v-row align="center">
                        <GenericInput type="text" inputDesign="inline_label" :value="form.seo_title_en"
                           @input="form.seo_title_en = $event" label="website title in SEO" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput dir="rtl" inputDesign="inline_label" type="text" :value="form.seo_title_ar"
                           @input="form.seo_title_ar = $event" label="عنوان الموقع في SEO" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />


                        <GenericInput type="textarea" inputDesign="inline_label" rowsNumber="9"
                           :value="form.seo_description_en" @input="form.seo_description_en = $event"
                           label="website description in SEO" :required="false" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]" />

                        <GenericInput dir="rtl" inputDesign="inline_label" type="textarea" rowsNumber="9"
                           :value="form.seo_description_ar" @input="form.seo_description_ar = $event"
                           label="تفاصيل الموقع في SEO" :required="false" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]" />

                        <GenericInput type="combobox" inputDesign="up_label" :dense="false" :value="form.seo_keywords"
                           @input="form.seo_keywords = $event" label="SEO keywords" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]" />


                     </v-row>
                  </v-tab-item>
               </v-tabs-items>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import { ControlMixins } from '@/mixins/ControlMixins';
export default {
   name: "WebsiteSettingsControl",
   mixins: [ControlMixins],
   data: () => ({
      pageData: {
         screen_code: "01-007",

         endpoint: 'Website',
         url: null,
         controlRoute: "/website-control/",
         entityName: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      tab: 0,
      form: {
         primary_logo: null,
         secondary_logo: null,
         mobile: null,
         fax: null,
         email: null,
         facebook: null,
         youtube: null,
         whatsApp: null,
         instagram: null,
         twitter: null,
         linkedIn: null,
         address_ar: null,
         address_en: null,
      },
   }),
   components: {

   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      getData() {
         this.pageData.isEdit = true
         this.pageData.isLoading = true;
         this.$api.GET_METHOD(`${this.pageData.show_endpoint}/1`).then((response) => {
            this.pageData.editIsLoading = false;
            this.pageData.isLoading = false;
            if (response.status) {
               console.log("response", response);
               this.form = response.data;
               // this.$refs.form.validate();
            }
         })
      }
   },
};
</script>
