export const EntityMixins = {
  watch: {
    $route() {
      this.pageData.queryParam = new URLSearchParams();
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    tableHeader() {
      this.pageData.tableHeader = this.tableHeader || [];
    },
  },
  methods: {
    queryParams() {
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.pageSize) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.search || '';
      this.pageData.endpoint = this.$store.state.activeScreen.endpoint || this.pageData.endpoint;
      this.pageData.queryParam = new URLSearchParams(this.$store.state.activeScreen.queryParams || '');
    },
    pageMainData() {
      this.pageData.screen_code = this.$store.getters.activeScreen.screen_code || null;
      this.pageData.category = this.$store.getters.activeScreen.category_name || null;
      this.pageData.entityName = this.$store.getters.activeScreen.name || null;
      this.pageData.tableHeader = this.tableHeader || [];
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.options = {
        view: true,
        create: true,
        edit: true,
        delete: true,
        print: true,
        restore: true,
      };
    },
    getData() {
      this.pageData.isLoading = true;
      this.$api
        .GET_METHOD(`${this.pageData.endpoint}?${this.pageData.queryParam.toString()}`).then((response) => {
          this.pageData.isLoading = false;
          if (response.success) {
            this.pageData.response = response.data;
            if (response.data['meta']) {
              this.pageData.rows = response.data[this.pageData.list_key];
              this.pageData.page = response.data['meta'].currentPage;
              this.pageData.pagination = {
                page: response.data['meta'].currentPage,
                totalPages: response.data['meta'].totalPages,
                per_page: response.data['meta'].itemsPerPage,
                totalRows: response.data['meta'].totalItems,
              };
            } else {
              this.pageData.rows = response.data;
            }

          }
        });
    },
    getSearch(search) {
      this.pageData.queryParam.set("search", search || '')
      this.pageData.queryParam.set("page", 1)
      let new_route = this.pageData.url + '?' + this.pageData.queryParam.toString().replace(/\w+=&/g, '').replace(/&\w+=$/, '')
      if (this.$route.fullPath !== new_route) {
        this.$router.push(new_route)
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam.set("page", page || 1);
      this.pageData.queryParam.set("pageSize", limit || 15);
      let new_route = this.pageData.url + '?' + this.pageData.queryParam.toString().replace(/\w+=&/g, '').replace(/&\w+=$/, '');
      if (this.$route.fullPath !== new_route) {
        this.$router.push(new_route)
      }
    },
    edit(row) {
      this.$router.replace(`/${this.pageData.controlRoute}/` + row.id);
    },
    toggleActiveMethod(INDEX) {
      let item = this.pageData.rows[INDEX] || {};
      this.pageData.rows[INDEX].isLoading = true
      this.$api.PUT_METHOD(`${this.pageData.endpoint}/${item.id}`, { isActive: item.isActive }).then((response) => {
        this.pageData.rows[INDEX].isLoading = false
        if (!response.status) {
          this.pageData.rows[INDEX].isActive = !this.pageData.rows[INDEX].isActive
        } else {
          this.pageData.rows[INDEX].updatedBy = this.$store.getters.user.email
        }
        this.pageData.rows = [...this.pageData.rows]
      });
    },
    deleteValueMethod(ID) {
      if (ID) {
        this.$api.DELETE_METHOD(`${this.pageData.endpoint}/${typeof ID === 'object' ? ID.id || ID.m_id : ID}`).then((response) => {
          if (response.status) {
            this.getData();
          }
        });
      }
    },
  },
};
