<template>
  <v-container fluid class="bgAuth light d-flex align-center justify-center pa-0" style="height: 100vh">
    <v-col cols="11" lg="4" md="6" sm="7">
      <img src="@/assets/img/svg/white_logo.svg" class="mx-auto d-block mb-5" height="60" alt="wms" />
      <v-card class="login-card backgroundW pa-5">
        <h5 class="text-h5 text-center my-3">{{ $t("Welcome Back") }}</h5>
        <div class="subtitle-2 text-center text--disabled">{{ $t('Enter your credentials to access your account') }}
        </div>
        <v-form ref="form" @submit="login()" v-model="valid" class="mt-3" lazy-validation>
          <GenericInput type="text" name="name" label="username" :dense="false" :value="form.username"
            :keydownEnter="login" @input="form.username = $event" :required="true" :isLoading="isLoading"
            :cols="[12, 12, 12]" />
          <GenericInput type="password" name="password" label="password" :dense="false" :value="form.password"
            :keydownEnter="login" @input="form.password = $event" :required="true" :isLoading="isLoading"
            :cols="[12, 12, 12]" />
          <v-col cols="12" sm="12">
            <v-btn @click="login" :loading="isLoading" block large class="d-block mi-start-auto rounded-lg shadow"
              color="primary">
              {{ $t("login") }}
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white" class="mdi-spin">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-col>
        </v-form>
      </v-card>
    </v-col>
  </v-container>
</template>
<script>

export default {
  name: "LoginPage",
  computed: {},
  watch: {

  },
  mounted() {
    this.$api.LOGOUT(false);
    localStorage.setItem('app_version', this.$store.state.app_version);
  },
  components: {},
  data: () => ({
    valid: false,
    isLoading: false,
    form: {
      username: null,
      password: null,
    }
  }),
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.$api.POST_METHOD('Auth/login', this.form, 'login successfully').then((response) => {
          this.isLoading = false;
          if (response.success) {
            console.log('user', response.data);
            this.$api.SET_AUTH(response.data);
            this.$router.push('/')
          }
        })
      }
      else {
        this.loading = false;
      }
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },
};
</script>

<style scoped lang="scss">
.bgAuth {
  background-image: url("@/assets/img/svg/auth_bg.jpg");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  &::after {
    background-color: rgba(0, 0, 0, 0.7);
    content: '';
    position: absolute;
    height: 100vh;
    width: 100vw;
  }

  img {
    position: relative;
    z-index: 555;
  }

  .login-card {
    //background: #fff;
    position: relative;
    z-index: 555;
    padding-bottom: 1.5rem;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 4px 28px rgb(0 0 0 / 5%) !important;
  }
}</style>