<template>
  <v-card elevation="0" class="ma-0 pa-0 px-5 transparent rounded-lg ">
    <v-row justify="space-between" align="center">
      <v-col cols="12" sm="auto" class="d-flex justify-center align-center">
        <div class="text-h6 primary--text mx-2 text-capitalize">
          <strong>{{ pageData.category }}</strong> <v-icon>mdi-circle-medium</v-icon>
          <strong>{{ pageData.controlName }}</strong> <v-icon>mdi-circle-medium</v-icon>
          <strong>{{ pageData.isEdit ? $t('update') : $t('add new') }}</strong> 
        </div>
        <slot name="rightSide"></slot>
      </v-col>
      <slot name="centerSide"></slot>
      <v-col cols="12" sm="auto" class="pa-0">
        <v-row justify="end" align="center">
          <slot name="leftSide"></slot>
          <v-col cols="12" sm="auto">

            <v-btn depressed @click="viewForm" v-if="editForm" :loading="pageData.isLoading" class="my-2 mx-2 rounded-lg"
              color="blue4" outlined :height="37">{{ $t("edit") }}
            </v-btn>

            <v-btn depressed @click="deleteDialog = true"
              v-if="pageData.isEdit == true  && form.active != 0 && showDelete"
              :loading="pageData.isLoading" class="my-2 mx-2 rounded-lg" color="error" :height="37">{{ $t("delete") }}
            </v-btn>

            <v-btn depressed @click="restoreMethod"
              v-if="pageData.isEdit == true  && form.active == 0 && showDelete"
              :loading="pageData.isLoading" class="my-2 mx-2 rounded-lg accent--text" color="white" :height="37">
              {{ $t("restore") }}</v-btn>
            <slot name="left"></slot>
            <v-btn depressed @click="actionBtnClick" v-if="actionBtn == true" :loading="pageData.isLoading"
              :disabled="!actionBtnValid" class="my-2 mx-2 white--text rounded-lg" :color="actionBtnColor" :height="37">
              <span>{{ $t(actionBtnText) }}</span>
            </v-btn>
            <v-btn depressed @click="cancelControl" v-if="showClose" class="my-2 mx-2 rounded-lg error--text" :height="37"
              color="transparent">
              {{ $t('cancel') }} <v-icon right>
                mdi-close</v-icon>
            </v-btn>

            <v-btn depressed @click="save" :min-width="90" v-if="showCreate" :loading="pageData.isLoading"
              :disabled="!valid" class="my-2 mx-2 rounded-lg white--text" :height="37" color="success">{{ pageData.isEdit ?
                $t('save') : $t(createBtnText)
              }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <DeleteConfirmation v-if="deleteDialog == true" :dialog="deleteDialog" :item="form.name ? form.name : null"
      :backValueMethod="returnedDelete"></DeleteConfirmation>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Settings");
export default {
  name: "ControlHeader",
  data: () => ({
    deleteDialog: false,
    hasDraft: false,
  }),
  computed: {
    ...mapState(["favourites"]),
  },
  mounted() {
    this.getDraft(false)
  },
  props: {
    pageData: { default: Object },
    valid: { default: false },
    form: { default: Object },
    save: { default: Function },
    deleteMethod: { default: Function },
    showDelete: { default: false },
    restoreMethod: { default: Function },
    showCreate: { default: true },
    showRefresh: { default: true },
    showDraft: { default: false },
    draftData: { default: Object },
    refreshData: { default: Function },
    createBtnText: { default: "create" },
    actionBtn: { default: false },
    actionBtnValid: { default: false },
    actionBtnColor: { default: 'accent' },
    actionBtnClick: { default: Function },
    actionBtnText: { default: "action btn" },
    editForm: { default: false },
    viewForm: { default: Function },
    showClose: { default: true },
    returnDraft: { default: Function },
  },
  watch: {
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F10':
          if (this.valid && this.showCreate) {
            this.save()
          }
          break;
        default:
          break;
      }
    },
  },
  methods: {
    ...mapActions(["addFavourites"]),

    addToFav() {
      const fav = {
        screen_code: this.pageData.screen_code,
        name: this.pageData.entityName,
        url: this.$route.params.id ? this.pageData.controlRoute + this.$route.params.id : this.pageData.controlRoute,
      };
      this.addFavourites(fav).finally(() => { });
    },
    returnedDelete(status) {
      this.deleteDialog = false;
      this.deleteMethod(status);
    },
    cancelControl() {
      if (this.pageData.isEdit) {
        this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
      } else {
        this.$router.push(this.$store.state.activeScreen.url)
      }
    },
    addDraft() {
      this.$global.SET_COOKIE(this.pageData.screen_code, JSON.stringify(this.draftData), 360).then((status) => {
        if (status) {
          console.log(status);
          this.hasDraft = true;
          this.$store.state.snackbarTitle = 'added to draft successfully';
          this.$store.state.snackbarType = "success";
          this.$store.state.showSnackbar = true;
        }
      })
    },
    clearDraft() {
      this.$global.DELETE_COOKIE(this.pageData.screen_code)
      this.hasDraft = false;
      this.$store.state.snackbarTitle = 'draft cleared successfully';
      this.$store.state.snackbarType = "success";
      this.$store.state.showSnackbar = true;
    },
    getDraft(returnToEntity = true) {
      this.$global.GET_COOKIE(this.pageData.screen_code).then((status) => {
        if (status) {
          this.hasDraft = true;
          if (returnToEntity) {
            console.log(JSON.parse(status));
            this.returnDraft(JSON.parse(status))
          }
        }
      })
    },
  },
  components: {}
};
</script>

<style scoped lang="scss"></style>
