<template>
   <div>
      <div class="d-flex">
         <v-col :cols="$store.state.sidebarIsOpen ? 12 : 4">
            <router-link to="/">
               <img src="@/assets/img/svg/white_logo.svg" :class="{ 'mb-2': $store.state.sidebarIsOpen }"
                  :height="!$store.state.sidebarIsOpen ? '40px' : '30px'" alt="GIECO" />
            </router-link>
         </v-col>
         <v-slide-x-transition>
            <v-col cols="8" class="px-1" v-if="!$store.state.sidebarIsOpen">
               <div class="caption font-weight-bold red1--text text-no-wrap">{{ $t('welcome') }}</div>
               <div class="body-2 white--text text-no-wrap">{{ $t('GIECO site admin panel') }}</div>
            </v-col>
         </v-slide-x-transition>
      </div>
      <v-row :justify="$store.state.sidebarIsOpen ? 'center' : 'end'">
         <v-col cols="auto" class="pa-5 pb-0 mt-2">
            <v-btn small @click.stop="toggleSidebar" :class="$vuetify.rtl == true ? '' : 'rotate-180'" fab color="primary"
               elevation="0" height="26" width="26">
               <v-icon color="white" v-if="!$store.state.sidebarIsOpen" size="20">mdi-chevron-right</v-icon>
               <v-icon color="white" v-if="$store.state.sidebarIsOpen" size="20">mdi-chevron-left</v-icon>
            </v-btn>
         </v-col>
      </v-row>
      <v-list dense class="mt-3 px-3">
         <!-- List Level 1 link -->
         <!-- Dashboard -->
         <span v-for="( screen, index ) in  screens " :key="index">
            <v-divider class="my-2" v-if="screen.group" />
            <v-subheader class="text-capitalize" v-if="screen.group">{{ screen.name }}</v-subheader>
            <v-list-item v-else active-class="activeItem" link :to="screen.url" color="primary"
               class="my-1 text-capitalize">

               <v-list-item-icon>
                  <v-icon :class="$store.state.sidebarIsOpen ? '' : 'mx-n2'">{{ screen.icon }}</v-icon>
               </v-list-item-icon>
               <v-list-item-title class="text-start">{{ $t(screen.name) | capitalize }}</v-list-item-title>
            </v-list-item>
         </span>
      </v-list>
   </div>
</template>

<script>
import screens from '@/plugins/screens.json';
export default {
   name: "Sidebar",
   mounted() {
      this.screens = screens
   },
   data: () => ({
      screens: []
   }),
   methods: {
      toggleSidebar() {
         this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
      },
      openPOS() {
         window.open(`https://pos.giecoerp.com/auto-login?clientID=${this.$store.state.clientID}&key=${this.$store.state.accessToken}`, '_blank')
      }
   }
};
</script>

<style scoped lang="scss">
.activeLink {
   opacity: 1 !important;
   font-weight: 500 !important;

   color: #212121;

   img:first-of-type {
      display: none;
   }

   img:last-of-type {
      display: block;
   }
}

span {
   img {
      width: 20px;
      height: 20px;
   }

   // img:last-of-type {
   //    display: none;
   // }
}

.v-list-item,
.v-list-item--link,
.v-list-item--active {
   // border-radius: 10px !important;
}

.theme--light .activeLink {
   background: transparent !important;
   color: #1a50ff;

   .v-list-item__title {
      font-weight: bold !important;
   }
}

.theme--light.v-application--is-rtl .activeLink {
   background: transparent !important;
   color: #1a50ff;

   .v-list-item__title {
      font-weight: bold !important;
   }
}

.theme--dark .activeLink {
   border-left: 4px solid #66b4e3;
   border-right: 0px solid #66b4e3;
}

.theme--dark.v-application--is-rtl .activeLink {
   border-left: 0 solid #66b4e3;
   border-right: 4px solid #66b4e3;
   color: #66b4e3;
}

.v-list-item img {
   filter: grayscale(100%) !important;
}

.v-list-item--active img {
   filter: grayscale(0%) !important;
}

.v-application--is-ltr .v-list-item__icon:first-child {
   margin-right: 12px !important;
}


.v-application--is-ltr .v-navigation-drawer--mini-variant .v-list-item__icon:first-child {
   margin-right: 0 !important;
}

.v-list-group .v-list-item__title {
   text-align: initial;
   font-size: 12px;
}

.v-list-group .v-list-item--link {
   text-align: initial;
   padding: 0 2.5rem;
}

.v-list-item--link {
   opacity: 0.7;
}

.v-application--is-rtl .v-navigation-drawer--mini-variant .v-list-item__icon:first-child {
   margin-right: 0 !important;
}

.v-application--is-rtl .v-list-item__icon:first-child {
   margin-left: 0;
   margin-right: 0;
}

.v-divider {
   opacity: 0.65 !important;
   width: 200px !important;
}

.theme--light {
   .v-list-group--active {
      background: #e6ecff !important;
      border-radius: 9px !important;
   }
}

.theme--dark {
   .v-list-item--link {
      opacity: 1;
   }

   .v-list-group--active {
      background: #191919 !important;
      border-radius: 9px !important;

      .v-list-item__title {
         color: #fff !important;
      }
   }

   .activeItem .v-list-item__title {
      color: var(--primary) !important;
      font-weight: 700 !important;
   }

   .activeItem {
      background: #7774684D !important;
      color: #7774684D !important;
      border-left: 3px solid var(--primary);
      border-radius: 0 !important;
   }

   .v-list .v-list-item--active .v-icon {
      color: var(--primary);
   }
}
.v-application--is-rtl .theme--dark .activeItem[data-v-98e9bf5e] {
   border: 0 !important;
   border-right: 3px solid var(--primary) !important;
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
   opacity: 0;
}

.v-list-group--sub-group .v-list-group__header.v-list-item--active .v-list-item__title {
   font-weight: bold;
   color: #1a50ff !important;
}


.v-list-group--sub-group .v-list-group__header.v-list-item--active .v-icon {
   color: #1a50ff !important;
   transform: rotate(180deg);
}

.activeItem {
   background: #e6ecff !important;
   border-radius: 9px !important;

   .v-list-item__icon {
      color: var(--primary) !important;
   }

   .v-list-item__title {
      font-weight: bold !important;
      color: var(--primary) !important;
   }
}
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) , .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) i {
   color: var(--gray9) !important;
}
</style>