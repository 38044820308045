<template>
  <div class="user_alert">
    <v-slide-y-reverse-transition>
      <v-alert v-show="showSnackbar" dense prominent class="border-0 shadow"  large
        :type="$store.state.snackbarType == 'loading'? 'info' : $store.state.snackbarType">
        <v-row align="center">
          <v-col class="grow">
            <div class="subtitle-1 font-weight-bold text-capitalize">
              <v-icon class="mdi-spin" color="white" v-if="$store.state.snackbarType == 'loading'">mdi-loading</v-icon>
              {{ $t($store.state.snackbarTitle) }}</div>
            <div class="body-1" v-for="(message, index) in $store.state.snackbarMessages" :key="index">{{ $t(message) }}
            </div>
          </v-col>
          <v-col class="shrink">
            <v-btn @click="$store.state.showSnackbar = false" color="transparent" depressed small fab><v-icon
                color="white">mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-slide-y-reverse-transition>
  </div>
</template>


<script>
export default {
  name: "UserAlert",
  props: {},
  data: () => ({

  }),
  computed: {
    showSnackbar() {
      return this.$store.state.showSnackbar
    }
  },
  watch: {
    showSnackbar() {
      if (this.showSnackbar) {
        setTimeout(() => {
          this.$store.state.showSnackbar = false;
          //this.$store.state.snackbarType = 'info';
          this.$store.state.snackbarTime = 4500;
          this.$store.state.snackbarTitle = null;
          this.$store.state.snackbarMessages = [];
        }, this.$store.state.snackbarTime);
      }
    }
  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>


<style scoped lang="scss">
.user_alert {
  position: fixed;
  z-index: 54;
  width: -webkit-fill-available;
  bottom: -16px;
  // left: 0;
  // right: 0;

  .v-alert {
    border-radius: 0 !important;
  }
}</style>
