<template>
  <section>
    <v-container fluid class="pa-4">
      <v-row>
        <v-col cols="12">
          <div class="text-h5 font-weight-bold primary--text">{{ $t('dashboard') }}</div>
          <div class="subtitle-1 mt-2 font-weight-bold">{{ $t('make your touch to improve GIECO website') }}</div>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-card class="shadow  statistics_card pa-5">
            <div class="text-h6  font-weight-bold">
              {{ $t('website visits') }}
            </div>
            <div class="text-h2 font-weight-bold primary--text counter" v-html="0" :data-target="website_visits" />
            <v-icon :color="$store.state.Settings.darkTheme ? 'white' : 'black'" size="100">mdi-web</v-icon>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-card class="shadow  statistics_card pa-5">
            <div class="text-h6  font-weight-bold">
              {{ $t('contact requests') }}
            </div>
            <div class="text-h2 font-weight-bold primary--text counter" v-html="0" :data-target="contact_requests" />
            <v-icon :color="$store.state.Settings.darkTheme ? 'white' : 'black'"
              size="100">mdi-message-reply-text-outline</v-icon>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-card class="shadow  statistics_card pa-5">
            <div class="text-h6  font-weight-bold">
              {{ $t('projects') }}
            </div>
            <div class="text-h2 font-weight-bold primary--text counter" v-html="0" :data-target="projects_count" />
            <v-icon :color="$store.state.Settings.darkTheme ? 'white' : 'black'"
              size="100">mdi-city-variant-outline</v-icon>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-card class="shadow  statistics_card pa-5">
            <div class="text-h6  font-weight-bold">
              {{ $t('services') }}
            </div>
            <div class="text-h2 font-weight-bold primary--text counter" v-html="0" :data-target="services_count" />
            <v-icon :color="$store.state.Settings.darkTheme ? 'white' : 'black'" size="100">mdi-handshake</v-icon>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-card class="shadow  statistics_card pa-5">
            <div class="text-h6  font-weight-bold">
              {{ $t('blog and news') }}
            </div>
            <div class="text-h2 font-weight-bold primary--text counter" v-html="0" :data-target="blogs_count" />
            <v-icon :color="$store.state.Settings.darkTheme ? 'white' : 'black'" size="100">mdi-newspaper</v-icon>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-card class="shadow  statistics_card pa-5">
            <div class="text-h6  font-weight-bold">
              {{ $t('partners') }}
            </div>
            <div class="text-h2 font-weight-bold primary--text counter" v-html="0" :data-target="partners_count" />
            <v-icon :color="$store.state.Settings.darkTheme ? 'white' : 'black'"
              size="100">mdi-account-group-outline</v-icon>
          </v-card>
        </v-col>


      </v-row>
    </v-container>
  </section>
</template>

<script>

export default {
  name: "AdminHome",
  computed: {},
  components: {

  },
  data: () => ({
    date: new Date(),
    loading: false,
    greeting: '',
    website_visits: 0,
    contact_requests: 0,
    projects_count: 0,
    services_count: 0,
    blogs_count: 0,
    partners_count: 0,
  }),

  methods: {
    getDashboard() {

      this.$api.GET_METHOD(`User/dashboard`).then((response) => {
        if (response.status) {
          this.website_visits = response.data.website_visits.visits || 0;
          this.contact_requests = response.data.contact_us_count || 0;
          this.projects_count = response.data.projects_count || 0;
          this.services_count = response.data.services_count || 0;
          this.blogs_count = response.data.blogs_count || 0;
          this.partners_count = response.data.partners_count || 0;
          setTimeout(() => {
            const counters = document.querySelectorAll('.counter')
            counters.forEach(counter => {
              counter.innerText = '0'
              const updateCounter = () => {
                const target = +counter.getAttribute('data-target')
                const c = +counter.innerText
                const increment = target / 200
                if (c < target) {
                  counter.innerText = `${Math.ceil(c + increment)}`
                  if (target <= 10) {
                    setTimeout(updateCounter, 200)
                  }
                  else if (target <= 50) {
                    setTimeout(updateCounter, 30)
                  }
                  else if (target <= 100) {
                    setTimeout(updateCounter, 20)
                  }
                  else if (target <= 300) {
                    setTimeout(updateCounter, 10)
                  } else {
                    setTimeout(updateCounter, 6)
                  }

                } else {
                  counter.innerText = target
                }
              }
              updateCounter()
            })
          }, 0);
        }
      })
    }
  },
  mounted() {
    this.getDashboard()
    const time = new Date().getHours();

    if (time >= 0 && time < 12) {
      this.greeting = "Good Morning";
    } else if (time == 12) {
      this.greeting = "Good Noon";
    } else if (time >= 12 && time <= 17) {
      this.greeting = "Good Afternoon";
    } else {
      this.greeting = "Good Evening!";
    }



    // setInterval(() => {

    // }, 1000);
  },
};
</script>

<style scoped lang="scss">
.statistics_card {
  .text-h2 {
    //
  }

  .v-icon {
    position: absolute;
    right: 0;
    opacity: 0.2;
    bottom: 0;
  }
}
</style>
