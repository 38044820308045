import axios from "axios";
import store from "../store";
import router from "../router";
//////////////////

///////// Axios Defaults Header /////////

axios.defaults.baseURL =
  // store.state.endpointURL + "api/v1/" + store.state.Settings.language + "/";
  store.state.endpointURL + "v1/";
axios.defaults.headers.common["access-token"] = store.state.apiAccess;
axios.defaults.headers.common["Authorization"] =
  "Bearer " + store.state.accessToken;
axios.defaults.headers.common["app_ver"] = `${store.state.app_version}`;
// axios.defaults.timeout = 20000;
export default {
  install(Vue) {
    // define a global property
    Vue.PLUGIN_VERSION = "0.0.3";
    Vue.prototype.$api = {
      serverUrl: store.state.endpointURL,
      // GET_METHOD ------------------------------------------------------------
      async GET_METHOD(RESOURCE_NAME) {
        try {
          const response = await axios.get(RESOURCE_NAME);
          if (response.status == 200 || response.status == 201) {
            return response.data;
          }
          else {
            this.RESPONSE_ERROR(response.response)
          }
        } catch (error) {
          this.RESPONSE_ERROR(error.response)
          return false;
        }
      },
      // POST_METHOD ------------------------------------------------------------
      async POST_METHOD(RESOURCE_NAME, NEW_DATA, MESSAGE = 'added successfully', SNACKBAR = true, RETURN_ERROR) {
        try {
          const response = await axios.post(RESOURCE_NAME, NEW_DATA);
          console.log('response api', response);
          if (response.status == 200 || response.status == 201) {
            if (SNACKBAR) {
              store.state.snackbarTitle = MESSAGE;
              store.state.snackbarType = response.data.status ? "success" : "error";
              store.state.showSnackbar = (MESSAGE);
            }
            return response.data;
          }
          else {
            if (RETURN_ERROR) {
              return response.data
            } else {
              this.RESPONSE_ERROR(response.response)
              return false;
            }
          }
        } catch (error) {
          if (RETURN_ERROR) {
            return error.response.data
          } else {
            this.RESPONSE_ERROR(error.response)
            return false;
          }
        }
      },
      //  POST Multipart File ------------------------------------------------------------
      async POST_METHOD_MULTIPART(RESOURCE_NAME, NEW_DATA, MESSAGE = 'added successfully', SNACKBAR = true, RETURN_ERROR) {
        try {
          const formData = new FormData();
          Object.keys(NEW_DATA).forEach((key) => {
            formData.append(key, NEW_DATA[key]);
          });
          const response = await axios.request({
            method: "post",
            url: `${store.state.endpointURL + "v1"}/${RESOURCE_NAME}`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });

          if (response.status == 200 || response.status == 201) {
            if (SNACKBAR) {
              store.state.snackbarTitle = MESSAGE;
              store.state.snackbarType = response.data.status ? "success" : "error";
              store.state.showSnackbar = (MESSAGE);
            }
            return response.data;
          }
          else {
            if (RETURN_ERROR) {
              return response.data
            } else {
              this.RESPONSE_ERROR(response.response)
              return false;
            }
          }
        } catch (error) {
          this.RESPONSE_ERROR(error.response)
          return false;
        }
      },
      // PUT_METHOD ------------------------------------------------------------
      async PUT_METHOD(RESOURCE_NAME, NEW_DATA, MESSAGE = 'updated successfully', SNACKBAR = true, RETURN_ERROR) {
        try {
          const response = await axios.put(RESOURCE_NAME, NEW_DATA);
          if (response.status == 200 || response.status == 201) {
            if (SNACKBAR) {
              store.state.snackbarTitle = MESSAGE;
              store.state.snackbarType = response.data.status ? "success" : "error";
              store.state.showSnackbar = (MESSAGE);
            }
            return response.data;
          }
          else {
            if (RETURN_ERROR) {
              return response.data
            } else {
              this.RESPONSE_ERROR(response.response)
              return false;
            }
          }
        } catch (error) {
          if (RETURN_ERROR) {
            return error.response.data
          } else {
            this.RESPONSE_ERROR(error.response)
            return false;
          }
        }
      },
      //  PUT Multipart File ------------------------------------------------------------
      async PUT_METHOD_MULTIPART(RESOURCE_NAME, NEW_DATA, MESSAGE = 'added successfully', SNACKBAR = true, RETURN_ERROR) {
        try {
          const formData = new FormData();
          Object.keys(NEW_DATA).forEach((key) => {
            formData.append(key, NEW_DATA[key]);
          });
          const response = await axios.request({
            method: "put",
            url: `${store.state.endpointURL + "v1"}/${RESOURCE_NAME}`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });

          if (response.status == 200 || response.status == 201) {
            if (SNACKBAR) {
              store.state.snackbarTitle = MESSAGE;
              store.state.snackbarType = response.data.status ? "success" : "error";
              store.state.showSnackbar = (MESSAGE);
            }
            return response.data;
          }
          else {
            if (RETURN_ERROR) {
              return response.data
            } else {
              this.RESPONSE_ERROR(response.response)
              return false;
            }
          }
        } catch (error) {
          this.RESPONSE_ERROR(error.response)
          return false;
        }
      },
      // DELETE_METHOD ------------------------------------------------------------
      async DELETE_METHOD(RESOURCE_NAME, MESSAGE = 'deleted successfully', SNACKBAR = true, RETURN_ERROR) {
        try {
          const response = await axios.delete(RESOURCE_NAME);
          console.log('response api', response);
          if (response.status == 200 || response.status == 201) {
            if (SNACKBAR) {
              store.state.snackbarTitle = MESSAGE;
              store.state.snackbarType = response.data.status ? "success" : "error";
              store.state.showSnackbar = (MESSAGE);
            }
            return response.data;
          }
          else {
            if (RETURN_ERROR) {
              return response.data
            } else {
              this.RESPONSE_ERROR(response.response)
              return false;
            }
          }
        } catch (error) {
          if (RETURN_ERROR) {
            return error.response.data
          } else {
            this.RESPONSE_ERROR(error.response)
            return false;
          }
        }
      },

      // SET_AUTH
      SET_AUTH(AUTH_DATA) {
        store.state.splashScreen = true;
        AUTH_DATA.image = '';
        localStorage.setItem(
          Vue.prototype.$global.CRYPT("gieco", "access-token"),
          Vue.prototype.$global.CRYPT("gieco", AUTH_DATA.token)
        );
        localStorage.setItem(
          Vue.prototype.$global.CRYPT("gieco", "user"),
          Vue.prototype.$global.CRYPT("gieco", JSON.stringify(AUTH_DATA))
        );
        // Request Header
        axios.defaults.headers.common["Authorization"] = "Bearer " + AUTH_DATA.token;
        store.state.userData = AUTH_DATA;
        store.state.isAuth = true;
        store.state.splashScreen = false;
        return AUTH_DATA;
      },

      ///////// Logout /////////
      LOGOUT(reload = true) {
        store.state.isAuth = false;
        store.state.accessToken = false;
        var lang = localStorage.getItem("language");
        localStorage.clear();
        localStorage.setItem("language", lang ? lang : "en");
        axios.defaults.headers.common["Authorization"] = null;
        if (reload == true) {
          window.location.reload(true);
        } else {
          if (router.currentRoute.fullPath !== '/login')
          router.push("/login").catch(() => { });
        }
      },

      //  API Errors Action ------------------------------------------------------------
      RESPONSE_ERROR(RESPONSE) {
        console.log('$api RESPONSE_ERROR', RESPONSE);
        store.state.snackbarTitle = RESPONSE?.data?.message;
        store.state.snackbarType = "error";
        if (RESPONSE?.data?.errors) {
          Object.keys(RESPONSE?.data?.errors).forEach((key) => {
            console.log(RESPONSE.data.errors[key]);
            Object.keys(RESPONSE.data.errors[key]).forEach(error => {
              console.log(error, RESPONSE.data.errors[key][error]);
              store.state.snackbarMessages.push(RESPONSE.data.errors[key][error]);
            });
          });
        }
        store.state.showSnackbar = true;
        if (RESPONSE.status == 403 || RESPONSE.status == 401) {
          this.LOGOUT(false);
        }
      },

      ///////// Check Internet Connection /////////
      CHECK_INTERNET() {
        return navigator.onLine;
      },
    };

  },
};
