<template>
  <div>
    <v-main v-if="$store.state.splashScreen == false">
      <v-container fluid>
        <v-row>
          <v-navigation-drawer dark class="shadow border-0" style="z-index : 66" :right="$vuetify.rtl == true"
            mini-variant-width="80" :mini-variant.sync="$store.state.sidebarIsOpen" :mobile-breakpoint="991" app>
            <Sidebar />
          </v-navigation-drawer>
          
          <v-container fluid class="pa-0">
            <Appbar />
            <router-view />
          </v-container>
        </v-row>
      </v-container>


      <v-footer outlined min-width="100%" style="z-index: 99999999 !important;" fixed padless
        v-if="networkConnection == false">
        <v-alert color="red6" width="100%" dense elevation="0" class="ma-0" rounded="0">
          <p class="text-center  white--text font-weight-bold ma-0" style="width: 100%;">
            {{ $t("No Internet Connection") }}
            <v-icon color="white" class="mx-2">mdi-wifi-off</v-icon>
          </p>
        </v-alert>
      </v-footer>
      <RequestLoading />
      <UserAlert />
      <HotKeysDialog />
    </v-main>
  </div>
</template>


<script>
import Appbar from "@/components/ui/Appbar.vue";
import Sidebar from "@/components/ui/Sidebar.vue";
import UserAlert from "@/components/ui/UserAlert.vue";
import RequestLoading from "@/components/ui/RequestLoading.vue";
import HotKeysDialog from "@/components/modals/HotKeysDialog.vue";
export default {
  name: "AdminModule",
  components: {
    Appbar,
    Sidebar,
    UserAlert,
    RequestLoading,
    HotKeysDialog
  },
  mounted() {
    this.darkTheme = this.$store.state.Settings.darkTheme;
    setInterval(() => {
      this.networkConnection = this.$api.CHECK_INTERNET();
      this.$store.state.networkConnection = this.$api.CHECK_INTERNET();
    }, 2000);


    window.addEventListener("keydown", e => {
      // console.log('key', e.key);
      this.$store.commit('Settings/SET_KEY_CLICKED', e.key);
      setTimeout(() => {
        this.$store.commit('Settings/SET_KEY_CLICKED', null);
      }, 100);
      switch (e.key) {
        case 'F1':
        case 'F3':
        case 'F5':
        case 'F6':
        case 'F7':
        case 'F10':
          e.preventDefault();
          break;
        default:
          break;
      }
    });


  },
  data: () => ({
    mini: false,
    darkTheme: false,
    networkConnection: Boolean
  }),

  computed: {},
  watch: {},
  methods: {
    darkThemee() {
      this.$store.dispatch("Settings/darkTheme");
    },
  },

}
</script>
<style scoped></style>