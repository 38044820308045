
<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pa-7">
               <v-row align="center">
                  <GenericInput type="text" inputDesign="inline_label" :value="form.title_en" @input="form.title_en = $event"
                     label="certificate" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput dir="rtl" inputDesign="inline_label" type="text" :value="form.title_ar"
                     @input="form.title_ar = $event" label="الشهاده" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]" />

                  <GenericInput type="texteditor" :value="form.description_en" @input="form.description_en = $event"
                     label="certificate details" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput dir="rtl" type="texteditor" :value="form.description_ar" @input="form.description_ar = $event"
                     label="تفاصيل الشهاده" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="file" paddingY="py-5" inputDesign="inline_label" :value="form.image" @input="form.image = $event"
                     label="image" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

               </v-row>

            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import { ControlMixins } from '@/mixins/ControlMixins';
export default {
   name: "CertificatesControl",
   mixins: [ControlMixins],
   data: () => ({
      pageData: {
         screen_code: "01-012",
         endpoint: 'Certificates',

         url: null,
         controlRoute: "/certificates-control/",
         entityName: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      form: {
         title_ar: null,
         title_en: null,
         description_ar: null,
         description_en: null,
         image: null,
      },
   }),
   components: {

   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.getData();

   },
   methods: {

   },
};
</script>
