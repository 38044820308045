<template>
  <div class="d-flex align-center searchInput">
    <v-autocomplete v-model="select" clearable color="primary" dense :loading="isLoading" :items="items"
      :search-input.sync="search" item-text="name" item-value="entity_id" hide-no-data hide-details
      :placeholder="$i18n.t('search...')" :no-data-text="$i18n.t('no search result')" outlined return-object
      @keydown.enter="fetchData()" @click:clear="items = []">
      <template v-slot:item="{ item }">
        <div class="subtitle-2 pa-1 d-flex align-center" style="width : 100%">
          <!-- <img loading="lazy" width="50" class="me-2" v-if="resultImage"
            :src="item.thumbnail ? $store.state.endpointURL + item.thumbnail : require('@/assets/img/svg/logo.svg')"
            :alt="item.name" :title="item.name"> -->
          <div>
            <div>{{ item.name }}</div>
            <v-chip x-small :color="item.color" class="me-2">{{ item.entitytype }}</v-chip>
          </div>
        </div>
        <v-divider></v-divider>
      </template>
      <template v-slot:append>
        <v-icon v-if="isLoading" class="spinner">mdi-loading</v-icon>
        <v-icon style="position: relative;z-index: 5555;" @click="fetchData" v-else
          class="cursor_pointer ">mdi-magnify</v-icon>
      </template>
    </v-autocomplete>

  </div>
</template>


<script>
export default {
  name: "SearchInput",
  data: () => ({
    search: null,
    select: null,
    isLoading: false,
    items: [],
  }),
  props: {
    resultImage: { default: true },
  },
  watch: {
    search(val) {
      if (this.search) {
        setTimeout(() => {
          if (this.search === val) {
            this.fetchData(val)
          }
        }, 750);
      } else {
        this.items = [];
      }
    },
    select() {
      if (this.select) {
        this.showItem(this.select)
      }
    },
  },
  computed: {

  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`search/${this.search}`).then(response => {
        this.isLoading = false;
        if (response) {
          this.items = [];
          response.data.forEach(item => {
            let activeLang = this.$i18n.locale
            item.name = item[`name_${activeLang}`] || item[`title_${activeLang}`] || item[`email`];
            item.thumbnail = item[`image_${activeLang}`] || item[`icon`] || item[`image`] || null;
            switch (item.entitytype) {
              case 'Blog':
                item.color = 'info';
                item.entity_id = `Blog_${item.id}`;
                item.route = `/blogs-news-control/${item.id}`;
                break;
              case 'Services':
                item.color = 'warning';
                item.entity_id = `Service_${item.id}`;
                item.route = `/services-control/${item.id}`;
                break;
              case 'Project':
                item.color = 'success';
                item.entity_id = `Project_${item.id}`;
                item.route = `/projects-control/${item.id}`;
                break;
              case 'User':
                item.color = 'error';
                item.entity_id = `User_${item.id}`;
                item.route = `/users-control/${item.id}`;
                break;
              case 'Partner':
                item.color = 'secondary';
                item.entity_id = `Partner_${item.id}`;
                item.route = `/partners-control/${item.id}`;
                break;
              case 'Portfolio':
                item.color = 'light';
                item.entity_id = `portfolio_${item.id}`;
                item.route = `/portfolio-control/${item.id}`;
                break;
              default:
                break;
            }
          });
          this.items = [...response.data] || [];
          console.log(this.items);
        }
      })
    },
    showItem({ route }) {
      if (route !== this.$router.currentRoute.path) {
        this.$router.push(route);
      }
    },
  }

};
</script>



<style scoped lang="scss">
@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation-name: spinner;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.appendIcon {
  position: absolute !important;
  right: 16px;
  top: 10px;
  z-index: 20;
}

.v-application--is-rtl {
  .appendIcon {
    left: 16px;
    right: unset;
  }
}
</style>
