<template>
  <v-col cols="12">
    <v-card class="backgroundW  pa-5 pt-7" :class="shadow ? 'shadow' : 'elevation-0'">
      <v-row v-if="!loading" align="center">
        <v-col :cols="item.cols || 12" :sm="item.sm || 6" :md="item.md || 4" :lg="item.lg || 3" :xl="item.xl || 3"
          v-for="(item, index) in summery" :key="index">
          <div class="subtitle-1" :class="item.class || 'primary--text'">
            <span class="font-weight-bold" v-if="item.label">{{ $t(item.label) | capitalize }} :</span>
            <span v-if="item.type == 'float'">{{ item.value || 0  | float}}</span>
            <span v-else-if="item.type == 'price'">{{ item.value || 0 | float }} {{ $t('ryal') }}</span>
            <span v-else-if="item.type == 'date'">{{ item.value || '-' | dateAr }}</span>
            <span v-else-if="item.type == 'parentage'">{{ item.value || 0  }}%</span>
            <span v-else-if="item.type == 'slot'"><slot name="value" :item="item"></slot></span>
            <span v-if="!item.type || item.type == 'text'">{{ item.value || '-' }}</span>
          </div>
        </v-col>
        <slot name="col"></slot>
      </v-row>
      <v-row v-if="loading" justify="center">
        <v-col cols="auto" class="my-15">
          <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary"
            indeterminate />
          <p class="text-center">{{ $t("Loading data") }}</p>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>

export default {
  name: "ControlSummery",
  data: () => ({}),
  computed: {},
  props: {
    summery: { default: {} },
    loading: { default: false },
    shadow: { default: true },
  },
  methods: {},
  watch: {},
  components: {}
};
</script>

<style scoped lang="scss">
</style>
