<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :option="pageData.options" :data="pageData.rows"
            :header="tableHeader" :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteValueMethod"
            :toggleActiveMethod="toggleActiveMethod" />
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { EntityMixins } from "@/mixins/EntityMixins";
export default {
  name: "UsersEntity",
  mixins: [EntityMixins],
  data: () => ({
    pageData: {
      screen_code: "01-002",
      endpoint: null,
      list_key: 'items',
      response: {},
      controlRoute: "users-control",
      url: null,
      entityName: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: new URLSearchParams(),
    },
  }),
  components: {},
  watch: {
    'pageData.rows'() {
      this.pageData.rows.map(row => {
        if (+this.$store.getters.user.id == +row.id) {
          row.isActiveDisabled = true;
          row.disableDelete = true;
        }
      });
    }
  },
  computed: {
    tableHeader() {
      let header = [
        { text: "#", key: "id", type: 'text', classes: "" },
        { text: "image", key: "image", type: 'image', classes: "" },
        { text: "name", key: "name", type: 'text', classes: "" },
        { text: "username", key: "email", type: 'text', classes: "" },
        { text: "mobile", key: "mobile", type: 'text', classes: "" },
        { text: "last seen", key: "lastSeen", type: 'date', classes: "" },
        { text: "logged in?", key: "online", type: 'boolean', classes: "" },
        { text: "active", key: "isActive", type: 'boolean', classes: "" },
      ]
      if (this.$store.getters.user.superAdmin) {
        header.pop()
        header.push(
          { text: "active", key: "isActive", type: 'switch', classes: "" },
          { text: "actions", key: "id", type: 'actions', classes: "" },
        )
      }
      return header
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
    this.pageData.options = {
      print: this.$store.getters.user.superAdmin,
      delete: this.$store.getters.user.superAdmin,
      create: this.$store.getters.user.superAdmin,
      edit: this.$store.getters.user.superAdmin,
      restore: this.$store.getters.user.superAdmin,
      view: this.$store.getters.user.superAdmin,
    };
  },
  methods: {},
};
</script>
