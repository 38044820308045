<template>
  <v-dialog v-model="dialog" transition="fade-transition" content-class="shadow-none" :hide-overlay="!requestLoader.isDialog" persistent width="300">
    <v-card color="backgroundW" class="pa-5 shadow-none py-6">
      <div class="d-flex justify-center">
        <v-progress-circular :rotate="360" :size="180" :width="20" :value="value"
          :color="value >= 100 ? 'success' : 'secondary'">
          <div class="text-h4 font-weight-bold">{{ value }}</div>
        </v-progress-circular>
      </div>
      <div :class="value >= 100 ? 'success--text' : 'secondary--text'" class="text-h5 mt-5 text-center font-weight-bold">
        {{ value >= 100 ? $t(requestLoader.successMessage) : $t(requestLoader.loadingMessage) }}</div>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  name: "RequestLoading",
  props: {},
  data: () => ({
    interval: {},
    value: 0,
    dialog: false,
  }),
  computed: {
    requestLoader() {
      return this.$store.state.requestLoader
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  watch: {
    'requestLoader.isLoading'() {
      if (this.requestLoader.isLoading) {
        this.value = 0;
        this.dialog = true;
        this.interval = setInterval(() => {
          if (this.value == 98) {
            clearInterval(this.interval)
          }
          else if (this.value >= 0 && this.value <= 79) {
            this.value += 10;
          }
          else if (this.value >= 80 && this.value <= 98) {
            this.value += 2;
          }
          else {
            this.value = 100;
          }
        }, 100)
      } else {
        this.value = 100;
        setTimeout(() => {
          this.dialog = false;
        }, 1000);
      }
    },
    dialog() {
      if (!this.dialog) {
        this.$store.commit('RESET_REQUEST_LOADER')
      }
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.$store.state.isLoading = true
    // }, 1000);

    // setTimeout(() => {
    //   this.$store.state.isLoading = false
    // }, 6000);
  },

  methods: {

  },
};
</script>


<style scoped lang="scss">
.user_alert {
  position: fixed;
  z-index: 54;
  width: -webkit-fill-available;
  bottom: -16px;
  // left: 0;
  // right: 0;

  .v-alert {
    border-radius: 0 !important;
  }
}
</style>
