import Vue from "vue";
var Tafgeet = require("tafgeetjs");

import vuetify from "@/plugins/vuetify";
Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("encrypt", function (value) {
  return Vue.prototype.$CryptoJS.enc.Base64.stringify(
    Vue.prototype.$CryptoJS.enc.Utf8.parse(value)
  );
});
Vue.filter("decrypt", function (value) {
  return Vue.prototype.$CryptoJS.enc.Base64.parse(value).toString(
    Vue.prototype.$CryptoJS.enc.Utf8
  );
});
Vue.filter("double", function (NUMBER) {
  return Number(NUMBER).toFixed(2);
});
Vue.filter("float", function (NUMBER) {
  let number = Number(NUMBER).toFixed(2);
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});
Vue.filter("noFixed", function (NUMBER) {
  let number = Number(NUMBER);
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});
Vue.filter("nonZero", function (NUMBER) {
  let number = NUMBER ? NUMBER.toFixed(2) : 0
  if(number > 0)
  return  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else   if(number < 0) 
  return '(' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ')';
  else
  return '-'
});
Vue.filter("tafqeet", function (NUMBER) {
  var stringText = new Tafgeet(NUMBER, "SAR").parse();
  return stringText;
});
Vue.filter("time", function (value) {
  // console.log(Vue.prototype.vuetify.rtl , 'Vue.prototype.vuetify.rtl');
  const date = new Date().toLocaleDateString();
  const time =  new Date(`${date} ${value}:00`).toLocaleTimeString("ar", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }) 
  return value ? time  : '--:--:--'
});
Vue.filter("date", function (value) {
  if(vuetify.rtl){
    return new Date(value).toLocaleDateString("ar", {
      weekday: "short",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }else{
    return new Date(value).toLocaleDateString("en-UK", {
      weekday: "short",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

});
Vue.filter("dateAr", function (value) {
  return new Date(value).toLocaleDateString("ar", {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
});
Vue.filter("dateEn", function (value) {
  return new Date(value).toLocaleDateString("en-UK", {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
});

export default {};
