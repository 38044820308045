<template>

  <v-dialog v-model="dialog" overlay-opacity="0.75" persistent max-width="600">
    <v-card relative class="backgroundW">
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
        color="grey lighten-1" text @click="closeDialog">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <v-card-title class="text-h6 font-weight-bold">
        <!-- <img v-if="dialogContent.icon == 'info'" src="../../assets/img/png/watchOut.png" height="30" class="mx-2" alt="erp" /> -->
        <v-icon size="25" class="mx-2">mdi-paperclip</v-icon>
        <div class="text-h6 font-weight-bold">{{ data.attach_ext ? $t('download/edit attachment') : $t('add attachment')
        }}</div>
      </v-card-title>
      <v-col cols="12" sm="12" class="px-7" v-if="data.attach_ext">
        <div class=" d-flex justify-space-between">
          <div>{{ $t('attach') }}_{{ $t(entityName) }}_{{ data[dataKey] }}</div>
          <v-btn color="success" :loading="printLoading" @click="downloadAttachment" text>{{ $t('download') }} <v-icon
              class="mx-1">
              mdi-download</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="px-7">
        <GenericInput type="text" :value="attachment_name" @input="attachment_name = $event" label="attachment name"
          :required="true"  :isLoading="false" :cols="[12, 12, 12]" />

        <GenericInput type="file" :base64="!multiPart" :value="attachment" @input="attachment = $event" label="choose attachment"
          :multi="false" :required="true" :isLoading="false" :cols="[12, 12, 12]" />
      </v-col>


      <v-col cols="12" class="pa-7">
        <v-row justify="end">
          <v-col cols="auto">
            <v-btn depressed color="error" text plain @click="closeDialog">
              {{ $t('close') }}</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn depressed color="success" :loading="isLoading" :disabled="!(attachment)" min-width="110"
              @click="uploadAttachment">
              {{ $t('add') }}</v-btn>
          </v-col>

        </v-row>
      </v-col>


    </v-card>
  </v-dialog>

</template>



<style scoped lang="scss">

</style>



<script>
import GenericInput from '../ui/GenericInput.vue';

export default {
  name: "AttachmentDialog",
  props: {
    returnMethod: { type: Function },
    dialog: { default: false },
    attachmentName: { default: false },
    multiPart: { default: false },
    returnAttachOnly: { default: false },
    uploadKey: { default: null },
    returnAttachMethod: { type: Function },
    downloadResource: { default: '' },
    entityName: { default: 'file' },
    dataKey: { default: 'id' },
    uploadResource: { default: '' },
    data: { default: Object },
  },
  computed: {},
  data: () => ({
    printLoading: false,
    attachment: null,
    attachment_name: null,
    isLoading: false,

  }),
  methods: {
    closeDialog() {
      this.returnMethod();
    },
    downloadAttachment() {
      this.printLoading = true;
      const src = `${this.downloadResource}/${this.data[this.dataKey]}`;
      const download_file_name = `${this.$i18n.t('attach')}_${this.$i18n.t(this.entityName)}_${this.data[this.dataKey]}.${this.data.attach_ext}`
      this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
        this.printLoading = false;
      })
    },
    uploadAttachment() {
      if (this.returnAttachOnly) {
        const attachBody = {
          id: 0,
          file_path: this.attachment,
          file_name: this.attachment_name,
          // attachment_name: this.attachment_name,
          file_extension: this.$global.FILE_TYPE(this.attachment),
        }
        this.attachment = null;
        this.attachment_name = null;
        this.returnAttachMethod(attachBody);
      } else {
        this.isLoading = true;
        var requestBody = null
        if (this.uploadKey) {
          requestBody = {
            [this.uploadKey]: [{
              id: this.data[this.dataKey],
              file: this.attachment,
              file_path: this.attachment,
              file_name: this.attachment_name,
              attachment_name: this.attachment_name,
            }]
          }
        } else {
          requestBody = {
            id: this.data[this.dataKey],
            file: this.attachment,
            file_path: this.attachment,
            file_name: this.attachment_name,
            attachment_name: this.attachment_name,
          }
        }
        if(this.multiPart)
        this.$api.POST_METHOD_MULTIPART(`${this.uploadResource}/${this.data[this.dataKey]}`, requestBody).then((res) => {
          this.isLoading = false;
          if (res.check) {
            this.attachment = null;
            this.attachment_name = null;
            this.returnMethod(true);
          }
        })
        else
        this.$api.POST_METHOD(`${this.uploadResource}/${this.data[this.dataKey]}`, requestBody).then((res) => {
          this.isLoading = false;
          if (res.check) {
            this.attachment = null;
            this.attachment_name = null;
            this.returnMethod(true);
          }
        })
      }

    },
  },
  mounted() {
  },
  components: { GenericInput, }
};
</script>
