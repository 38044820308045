
<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pb-7">
               <v-tabs v-model="tab">
                  <v-tab class="text-capitalize">{{ $t('project basic data') }}</v-tab>
                  <v-tab class="text-capitalize">{{ $t('search engine optimization') }} (SEO)</v-tab>
               </v-tabs>
               <v-tabs-items v-model="tab">

                  <v-tab-item class="ma-5">
                     <v-row align="center">
                        <GenericInput type="text" inputDesign="inline_label" :value="form.name_en"
                           @input="form.name_en = $event" label="project name" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput dir="rtl" inputDesign="inline_label" type="text" :value="form.name_ar"
                           @input="form.name_ar = $event" label="اسم المشروع" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="file" inputDesign="inline_label" :value="form.image_en"
                           @input="form.image_en = $event" label="project image" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput dir="rtl" inputDesign="inline_label" type="file" :value="form.image_ar"
                           @input="form.image_ar = $event" label="صوره المشروع" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="text" inputDesign="inline_label" :value="form.sub_title_en"
                           @input="form.sub_title_en = $event" label="subtitle" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput dir="rtl" inputDesign="inline_label" type="text" :value="form.sub_title_ar"
                           @input="form.sub_title_ar = $event" label="العنوان الفرعي" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <!-- <GenericInput type="combobox" inputDesign="inline_label" :value="form.tag_en"
                           @input="form.tag_en = $event" label="project tags" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput dir="rtl" type="combobox" inputDesign="inline_label" :value="form.tag_ar"
                           @input="form.tag_ar = $event" label="هشتاجات المشروع" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" /> -->

                        <GenericInput type="texteditor" :value="form.about_en" @input="form.about_en = $event"
                           label="project details" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]" />

                        <GenericInput dir="rtl" type="texteditor" :value="form.about_ar" @input="form.about_ar = $event"
                           label="تفاصيل المشروع" :required="true" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]" />

                        <GenericInput type="select"  :lookups="project_status" :value="form.projectStatus"
                           @input="form.projectStatus = $event" label="project status" :multi="false" :required="true"
                           selected_label="name" selected_prop="name" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]" />

                     </v-row>
                  </v-tab-item>

                  <v-tab-item class="ma-5">
                     <v-row align="center">
                        <GenericInput type="text" inputDesign="inline_label" :value="form.seo_title_en"
                           @input="form.seo_title_en = $event" label="project title in SEO" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput dir="rtl" inputDesign="inline_label" type="text" :value="form.seo_title_ar"
                           @input="form.seo_title_ar = $event" label="عنوان المشروع في SEO" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />


                        <GenericInput type="textarea" inputDesign="inline_label" rowsNumber="9"
                           :value="form.seo_description_en" @input="form.seo_description_en = $event"
                           label="project description in SEO" :required="false" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]" />

                        <GenericInput dir="rtl" inputDesign="inline_label" type="textarea" rowsNumber="9"
                           :value="form.seo_description_ar" @input="form.seo_description_ar = $event"
                           label="تفاصيل المشروع في SEO" :required="false" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]" />

                        <GenericInput type="combobox" inputDesign="inline_label" :value="form.seo_keywords"
                           @input="form.seo_keywords = $event" label="SEO keywords" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />


                     </v-row>
                  </v-tab-item>
               </v-tabs-items>

            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import { ControlMixins } from '@/mixins/ControlMixins';
export default {
   name: "ProjectsControl",
   mixins: [ControlMixins],
   data: () => ({
      pageData: {
         screen_code: "01-003",
         endpoint: 'Project',

         url: null,
         controlRoute: "/projects-control/",
         entityName: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      tab: 0,
      valid: false,
      project_status: [],
      form: {
         name_ar: '',
         name_en: '',
         image_ar: '',
         image_en: '',
         sub_title_ar: '',
         sub_title_en: '',
         about_ar: '',
         about_en: '',
         tag_ar: '',
         tag_en: '',
         projectStatus: 'Finished',
         // 
         seo_title_en: '',
         seo_title_ar: '',
         seo_description_en: '',
         seo_description_ar: '',
         seo_keywords: '',
      },
   }),
   components: {

   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.getData();
      this.project_status = [
         {
            name: 'Fresh Project',
         },
         {
            name: 'Under Construction',
         },
         {
            name: 'On Delivery',
         },
         {
            name: 'Finished',
         },
      ]
   },
   methods: {

   },
};
</script>
