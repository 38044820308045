<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :option="pageData.options" :data="pageData.rows"
            :header="tableHeader" :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteValueMethod"
            :toggleActiveMethod="toggleActiveMethod" />
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { EntityMixins } from "@/mixins/EntityMixins";
export default {
  name: "CertificatesEntity",
  mixins: [EntityMixins],
  data: () => ({
    pageData: {
      screen_code: "01-012",
      endpoint: null,
      list_key: 'items',
      response: {},
      controlRoute: "certificates-control",
      url: null,
      entityName: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: new URLSearchParams(),
    },
  }),
  components: {},
  computed: {
    tableHeader() {
      let header = [
        { text: "#", key: "id", type: 'text', classes: "" },
        { text: "image", key: "image", type: 'image', classes: "" },
        { text: "certificate", key: "title_en", type: 'text', classes: "" },
        { text: "شهادة", key: "title_ar", type: 'text', classes: "" },
        // { text: "description", key: "description_en", type: 'html', classes: "" },
        // { text: "الوصف", key: "description_ar", type: 'html', classes: "" },
        { text: "created by", key: "createdBy", type: 'text', classes: "" },
        { text: "created at", key: "createdAt", type: 'date', classes: "" },
        { text: "updated by", key: "updatedBy", type: 'text', classes: "" },
        { text: "updated at", key: "updatedAt", type: 'date', classes: "" },
        { text: "active", key: "isActive", type: 'switch', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ]
      // if (this.$store.getters.user.superAdmin) {}
      return header
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {},
};
</script>
