import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import screens from '@/plugins/screens.json';
// Views :-
import AdminModule from "@/views/Admin/AdminModule";



import Auth from "@/views/Auth/Auth";
import LoginPage from "@/views/Auth/LoginPage";
import Error500 from "@/views/Errors/Error500";
import SystemUpdate from "@/views/Errors/SystemUpdate";
import Error404 from "@/views/Errors/Error404";

// Modules Children Routes :-

import { AdminModuleChildrens } from "@/router/AdminModuleRoutes";


Vue.use(VueRouter);

// Routes :-
const routes = [
  // Authorized :-
  {
    path: "/",
    component: AdminModule,
    beforeEnter: (to, from, next) => {
      store.dispatch("checkAppVersion").then();
      store.dispatch("checkAuth").then();
      console.log("beforeEnter and checkAuth", store.state.isAuth);
      if (store.state.isAuth === true) {
        store.state.screens = screens.filter(screen => 'screen_code' in screen) || []
        // store.dispatch("getUserData").then();
        next();
      } else {
        router.push("/login").then();
      }
    },
    children: AdminModuleChildrens,
  },
  // Not Authorized :-
  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "/",
        name: "LoginPageAuth",
        component: LoginPage,
        meta: {
          name: "Login",
        },
      },
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
        meta: {
          name: "Login",
        },
      },
    ],
  },
  // Error Pages  :-
  {
    path: "/error500",
    name: "Error500",
    component: Error500,
  },
  {
    path: "/system_update",
    name: "SystemUpdate",
    component: SystemUpdate,
  },
  {
    path: "*",
    name: "Error404",
    component: Error404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("SET_ROUTE", to);
  next();
});

export default router;
