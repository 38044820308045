<template>
  <div id="pdfViewer"><!-- 1- PDF -->

    <div v-for="(page) in pages_count" :key="page" class="pdfPage" :class="`page_${size}`"> <!-- 2- Pages Loop -->

      <header class="col-12"> <!-- 3- Page Header -->

        <div style="display:flex; align-items: center; justify-content: space-between;"
          v-if="!header_slot && !customize_header_footer">
          <div>
            <h5>
              {{ $store.state.userData.name || ' ' }}
            </h5>
            <h6>
              {{ $store.state.userData.mobile || ' ' }}
            </h6>
          </div>
          <div>
            <img src="@/assets/logo.svg" style="max-height: 70px;max-width: 100px;" alt="">
          </div>
        </div>


        <v-row align="center" justify="center" v-else-if="customize_header_footer">
          <v-col cols="4">
            <div v-html="printStaticData.print_right" />
          </v-col>
          <v-col cols="4" class="logo">
            <img :src="printStaticData.logo" style="max-height: 120px;" alt="">
          </v-col>
          <v-col cols="4" dir="ltr">
            <div v-html="printStaticData.print_left" />
          </v-col>
        </v-row>

        <v-row align="center" justify="center" v-else>
          <slot name="pdf_header" :page="page" :pages_count="pages_count" :isLastPage="(pages_count === page)" />
        </v-row>
      </header> <!-- 3- End OF Header -->



      <div class="watermark" v-if="watermark"><!-- 4- Page Watermark -->
        <img src="@/assets/img/svg/logo.svg" alt="">
      </div> <!-- 4- End OF Watermark -->

      <div v-if="printTitle && page == 1" style="text-align: center;margin-top: -20px ;">
        <h5> {{ printTitle }}</h5>
        <h6 v-if="printSubTitle"><small>{{ printSubTitle }}</small></h6>
      </div>

      <section class="col-12" id="content" :class="size + ' ' + (invoice_defaults.print_footer_active ? '' : 'noFooter')">
        <!-- 5- Page Content -->
        <slot name="pdf_content" :page="page" :table_data="table_data(page)" :pages_count="pages_count"
          :isLastPage="(pages_count === page)" />
        <v-simple-table :fixed-header="fixedHeader" id="printTable" class="mt-3 d-none overflow-hidden"
          style="display: block; " :height="data.length >= 10 ? tableHeight : 'auto'" :dense="!$store.state.tableLarge"
          v-if="!content_slot">
          <template v-slot:default>

            <thead :class="{ 'headerGrouped': showGroupHeader }">
              <tr v-if="showGroupHeader">
                <th v-for="(group, groupIndex) in groupHeader" :key="groupIndex" :rowspan="group.rowspan"
                  :colspan="group.colspan" class="text-center group-header-th font-weight-bold">
                  {{ $t(group.text) | capitalize }}
                </th>
              </tr>
              <tr>
                <th v-for="(header, index) in header.filter((head) => head.type !== 'actions' && !head.hideInPrint && !head.is_rowspan)"
                  class="text-center" :style="{ 'min-width': header.width + 'px', 'background-color': header.background }"
                  :key="index">
                  <span>{{ $t(header.text) | capitalize }}</span>
                </th>
              </tr>
            </thead>


            <tbody :class="{ 'group-header': showGroupHeader }">
              <tr v-for="(row, index) in table_data(page)" :key="index" :class="row.rowClass">
                <td class="text-center"
                  v-for="(header, headerIndex) in header.filter((head) => head.type !== 'actions' && !head.hideInPrint)"
                  :style="{ 'min-width': header.width + 'px', 'background-color': header.background }"
                  :class="header.classes + ' ' + row.rowClass" :key="headerIndex">
                  <!-- Index -->
                  <span v-if="header.type == 'index'">{{ index + 1 }}</span>
                  <!-- text || search -->
                  <span v-else-if="header.type == 'text' || header.type == 'search'">{{ row[header.key] || '-' }}</span>
                  <span v-else-if="header.type == 'number'">{{ row[header.key] || 0 }}</span>
                  <!-- float -->
                  <span v-else-if="header.type == 'float'" :class="{ 'error--text': row[header.key] < 0 }">
                    {{ row[header.key] | nonZero }}
                  </span>
                  <!-- non_zero_float -->
                  <div v-else-if="header.type == 'non_zero_float'" dir="ltr"
                    :class="{ 'error--text': row[header.key] < 0 }">
                    {{ row[header.key] | nonZero }}
                  </div>
                  <!-- boolean -->
                  <span v-else-if="header.type == 'boolean' || header.type == 'switch'">
                      {{ row[header.key] ? $t('yes') : $t('no')}}
                  </span>
                  <!-- percentage -->
                  <span v-else-if="header.type == 'percentage'">{{ row[header.key] }} %</span>
                  <!-- price -->
                  <span v-else-if="header.type == 'price'">
                    {{ $global.DecimalFormatter((row[header.key] || 0).toFixed(2)) || '-' }}
                    {{ $t('sar') }}
                  </span>
                  <!-- weight -->
                  <span v-else-if="header.type == 'weight'">
                    {{ $global.DecimalFormatter((row[header.key] || 0).toFixed(2)) || '-' }}
                    {{ $t('gram') }}
                  </span>
                  <!-- pieces  -->
                  <span v-else-if="header.type == 'pieces'">{{ row[header.key] || '-' }} {{ $t('pieces') }}</span>
                  <!-- img  -->
                  <span v-else-if="header.type == 'img' || header.type == 'image'">
                    <v-avatar size="24" class="rounded-0" >
                      <img v-if="row[header.key]" :src="$store.state.endpointURL + row[header.key]"
                        style="max-height: 24px;" alt="" />
                    </v-avatar>
                  </span>
                  <!-- img base -->
                  <span v-else-if="header.type == 'img_base64'">
                    <v-avatar size="24" class="rounded-0">
                      <v-img v-if="row[header.key]" :src="row[header.key]" alt="" />
                      <v-img v-if="!row[header.key]" src="@/assets/img/png/files/notFound.png" alt="" />
                    </v-avatar>
                  </span>
                  <!-- array -->
                  <span v-else-if="header.type == 'array' && row[header.key]">
                    <div v-for="(text, index) in row[header.key].split(',').map(i => i)" :key="index" class="mx-1">
                      {{ text }}
                    </div>
                  </span>
                  <!-- imgs -->
                  <span v-else-if="header.type == 'imgs' && row[header.key]">
                    <v-avatar size="35" class="mr-n3 rounded-circle  white"
                      v-for="(img, index) in row[header.key].split(',').map(i => i)" :key="index">
                      <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on, attrs }">
                          <v-img v-bind="attrs" v-on="on" :src="$store.state.endpointURL + img" alt="" />
                        </template>
                        <span v-if="row.users_names">{{ row.users_names.split(',').map(i => i)[index] }}</span>
                      </v-tooltip>
                    </v-avatar>
                  </span>
                  <!-- slot -->
                  <span v-else-if="header.type == 'slot'" class="d-flex justify-center">
                    <slot name="td" :row="row" :header="header" :index="index"></slot>
                  </span>
                  <!-- date -->
                  <span v-else-if="header.type == 'date'"> {{ row[header.key] || '-' }}</span>
                  <span v-else>-</span>
                </td>
              </tr>
            </tbody>
            <tfoot v-if="footerData && pages_count === page">
              <tr v-for="(row, index) in footerData" :key="index">
                <th v-for="(header, headerIndex) in header" :class="header.classes"
                  :style="{ 'min-width': header.width + 'px' }" :key="headerIndex">
                  <!-- index -->
                  <span v-if="header.type == 'index'" class="font-weight-bold ">{{ row[header.key] || '-' }}</span>
                  <!-- text -->
                  <span v-if="header.type == 'text'" class="font-weight-bold ">{{ row[header.key] || '-' }}</span>
                  <!-- float -->
                  <span v-else-if="header.type == 'float'" class="font-weight-bold">
                    {{ $global.DecimalFormatter((row[header.key] || 0).toFixed(2)) || '-' }}
                  </span>
                  <!-- non_zero_float -->
                  <div v-else-if="header.type == 'non_zero_float'" dir="ltr"
                    :class="{ 'error--text': row[header.key] < 0 }">
                    {{ row[header.key] | nonZero }}
                  </div>
                  <!-- price -->
                  <span v-else-if="header.type == 'price'" class="font-weight-bold">
                    {{ $global.DecimalFormatter((row[header.key] || 0).toFixed(2)) || '-' }}
                    {{ $t('sar') }}</span>
                  <!-- weight -->
                  <span v-else-if="header.type == 'weight'" class="font-weight-bold">
                    {{ $global.DecimalFormatter((row[header.key] || 0).toFixed(2)) || '-' }}
                    {{ $t('gram') }}</span>
                  <!-- pieces  -->
                  <span v-else-if="header.type == 'pieces'" class="font-weight-bold">
                    {{ row[header.key] || '-' }} {{ $t('pieces') }}
                  </span>
                  <!-- slot -->
                  <span v-if="header.type == 'slot'" class="font-weight-bold ">{{ row[header.key] || '-' }}</span>

                  <!-- date -->
                  <span v-else-if="header.type == 'date'" class="font-weight-bold">
                    <span v-if="row[header.key]"> {{ row[header.key] }}</span>
                    <span v-else>-</span>
                  </span>

                  <span v-else></span>
                </th>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </section><!-- 5- End OF Content -->

      <!-- <footer class="col-12 pa-0" v-if="printStaticData.footer || invoice_defaults.print_footer_active">
        <v-col cols="12" class="footer_data">
          <slot name="pdf_footer" :page="page" :pages_count="pages_count" :isLastPage="(pages_count === page)" />
          <v-col cols="auto" class="text-center subtitle-1" v-if="!footer_slot && customize_header_footer">
            <div v-html="printStaticData.footer" />
          </v-col>
          <v-col cols="auto" class="pa-0" v-if="!footer_slot && !customize_header_footer">
            <div v-html="invoice_defaults.print_footer" />
          </v-col>
        </v-col>
      </footer> -->

      <footer v-if="!header_slot && !customize_header_footer">
        <h6>
          {{ application_name }}
          <div><small>{{ application_brief }}.</small></div>
        </h6>
        <h6>
          ( {{ page }} / {{ pages_count }} )
        </h6>
        <h6>
          {{ $store.state.userData.full_name || ' ' }}
          <div><small>{{ $global.GetCurrentDate() }} {{ $global.GetCurrentTime() }}</small></div>
        </h6>
      </footer>
      <!-- 6- End OF Footer -->


    </div><!-- 2- End OF Page Loop -->


  </div>
  <!-- 1- End OF PDF -->
</template>





<script>

export default {
  name: "PrintPDF",
  computed: {
    table_data() {
      return (active_page) => {
        let maxRows = this.invoice_defaults.print_footer_active ? this.maxPDFRows : this.maxPDFRows + 5
        return this.$global.PaginateArray(this.data, maxRows, active_page)
      }
    },
    pages_count() {
      let maxRows = this.invoice_defaults.print_footer_active ? this.maxPDFRows : this.maxPDFRows + 5
      return Math.ceil(this.data.length / maxRows)
    }
  },
  components: {

  },
  watch: {
  },
  props: {
    size: { default: 'a4' },
    data: { default: [] },
    header: { default: [] },
    footerData: { default: null },
    header_slot: { default: false },
    watermark: { default: false },
    content_slot: { default: false },
    customize_header_footer: { default: false },
    footer_slot: { default: false },
    showGroupHeader: { default: false },
    printTitle: { default: null },
    printSubTitle: { default: null },
    groupHeader: { default: null },
    fixedHeader: { default: true },
    maxPDFRows: { default: 12 },
    printStaticData: { default: Object },
    tableHeight: {
      default() {
        return this.$store.state.Settings.windowSize.y - 220
      },
    },
  },
  data: () => ({
    invoice_defaults: Object,
    application_name: '',
    application_brief: '',
  }),
  mounted() {
    this.invoice_defaults = {}
    this.application_name = this.$i18n.t('GIECO');
    // this.application_brief = this.$i18n.t('program for managing gold and jewelry stores');
  },
  methods: {

  },
};
</script>

<style lang="scss">
#printTable {
  border-radius: 0 !important;

  th {
    border-top: 0.3px solid #000 !important;
    border-right: 0.3px solid #000 !important;
    border-bottom: 0.3px solid #000 !important;
    background-color: #ddd !important;
    padding: 2px !important;
    font-weight: 500;
    height: auto;
    color: #000 !important;
    position: relative;
    top: 0;
    &:last-of-type {
      border-left: 0.3px solid #000 !important;
    }
  }

  tr {
    margin: 0 !important;
  }

  td {
    border-right: 0.3px solid #000 !important;
    border-bottom: 0.3px solid #000 !important;
    background-color: transparent !important;
    padding: 2px !important;
    font-weight: 500;
    color: #000 !important;
    height: auto;
    &:nth-of-type(1) {
      background: #eee !important;
   }
    &:last-of-type {
      border-left: 0.3px solid #000 !important;
    }
  }
}

.watermark {
  opacity: 0.1;
  z-index: 25;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    height: 150px;
  }
}

#pdfViewer {
  position: relative;
  padding: 0 1rem;

  .pdfPage {
    position: relative !important;

    &.page_a4 {
      height: 296.8mm !important;
      overflow: hidden !important;
      background-color: #fff !important;
    }
  }

  .ql-align-center {
    text-align: center !important;
  }

  .ql-align-right {
    text-align: right !important;
  }

  .ql-align-left {
    text-align: left !important;
  }

  #print_header_img {
    width: 100%;
  }

  header {
    min-height: 100px;

    .text-h6 {
      font-size: 14px;
    }

    .subtitle-1 {
      font-size: 12px;
    }

    .logo {
      img {
        max-height: 80px;
        margin: auto;
        display: block;
      }
    }
  }

  #content {
    overflow: hidden !important;

    &.a4 {
      height: calc(296.8mm - 150px - 40px) !important;

      &.noFooter {
        height: calc(296.8mm - 150px) !important;
      }
    }
  }

  footer {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 10px;

    h6 {
      width: 33.33%;
      text-align: center;

      &:first-of-type {
        text-align: start;
      }

      &:last-of-type {
        text-align: end;
      }
    }

    .subtitle-1 {
      font-size: 12px;
    }

    img {
      max-width: 100%;
    }

    //  background-color: #e6ecff;

    .footer_data {
      display: flex;
      justify-content: center;
      padding: 0 !important;

      .subtitle-1 {
        padding: 6px !important;
      }
    }
  }
}
</style>

