<template>
    <v-dialog v-model="dialog" overlay-opacity="0.75" scrollable :open-delay="0"
        :close-delay="0" hide-overlay fullscreen>
        <v-card height="90vh" :ripple="false" relative class="transparent shadow-none" id="img-carousel"
            @click="backValueMethod">
            <v-btn absolute class="mt-2 error" :style="$vuetify.rtl == true ? 'left: 10px' : 'right: 10px'" small fab
                color="white" text @click="closeDialog">
                <v-icon color="white" class="invert">mdi-close</v-icon>
            </v-btn>
            <img loading="lazy" class="d-block ma-auto"
                :style="{ maxHeight: $store.state.Settings.windowSize.y - 300 + 'px', maxWidth: $store.state.Settings.windowSize.x - 5 + 'px' }"
                :src="imageUrl" :alt="'gieco'">
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "ViewImage",
    props: {
        backValueMethod: { default: Function },
        dialog: { default: false },
        img: { default: '' },
    },
    computed: {
        imageUrl() {
            return this.$store.state.endpointURL + this.img
        }
    },
    data: () => ({

    }),
    watch: {


    },
    methods: {
        closeDialog() {
            this.backValueMethod(false);
        },
    },
    mounted() {
    }
};
</script>
  

<style scoped lang="scss">
#img-carousel {
    display: flex;
    position: relative;
    transition: 0;

    &::after {
        content: "";
        width: 100vw;
        overflow: hidden;
        height: 100vh;
        position: absolute;
        top: 0;
        background: rgba(0, 0, 0, 0.5);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(5.5px);
    }

    img {
        margin: auto;
        display: block;
        position: relative;
        max-height: 90vh;
        z-index: 2;
    }
}
</style>