import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "../store";
import i18n from "./i18n";
import * as XLSX from "xlsx";
import xlsx from "json-as-xlsx";
export default {
  install(Vue) {
    Vue.prototype.$global = {
      requiredRule: [(v) => !!v || i18n.t("required")],
      notRequiredRule: [],
      requiredNumberRule: [
        (v) =>
          parseFloat(String(v).replace(/,/g, "")) > 0 ||
          i18n.t("please enter valid number"),
      ],
      emailRule: [
        (v) => !!(v || "").match(/@/) || i18n.t("Please enter email address"),
      ],
      requiredNumberAcceptZeroRule: [
        (v) => v.toString().length >= 1 || i18n.t("please enter valid number"),
      ],

      mobileRule: [
        (v) =>
          !!(v || "").match(/^(?:[+0]9)?[0-9]{9,12}$/) ||
          i18n.t("Please enter mobile number"),
      ],
      urlRule: [
        (v) =>
          !!(v || "").match( /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi) ||
          i18n.t("Please enter vaild url"),
      ],
      passwordRule: [
        (v) => !!v || i18n.t("Password is required"),
        (v) =>
          (v && v.length >= 5) ||
          i18n.t("Password must be greater than 5 characters"),
      ],
      percentageRule: [
        (v) =>
          (v.toString().length >= 1 && v <= 100) ||
          i18n.t("percentage not valid"),
      ],
      // username
      usernameRule: [(v) => !!(v || "").match(/^[a-z][0-9a-z_]{2,23}[0-9a-z]$/) || i18n.t("not valid")],
      /////////  Array /////////
      FilterPermissions(Screen_Code) {
        if (store.state.screens.length > 0) {
          var find = store.state.screens.filter(
            (obj) => obj["screen_code"] === Screen_Code
          );
          return find[0];
        }
      },
      CheckAction(Screen_Code, Action) {
        var find = store.state.screens.filter(
          (obj) =>
            obj["screen_code"] === Screen_Code &&
            (obj["permission"].includes(Action.toString()) ||
              obj["permission"].includes(Number(+Action)))
        );
        return find.length > 0 ? true : false;
      },
      FilterArray(ARRAY, filterBy) {
        return ARRAY.filter((find) => find === filterBy);
      },
      FilterArrayOfObject(ARRAY, SearchIn, filterBy) {
        return ARRAY.filter((obj) => obj[SearchIn] === filterBy);
      },
      FilterArrayOfObjectElement(
        ARRAY,
        SearchIn,
        filterBy,
        ReturnElement = null
      ) {
        const filter = ARRAY.filter((obj) => obj[SearchIn] === filterBy);
        return filter.length ? filter.at(0)[ReturnElement] : null;
      },
      FilterObjectByArray(OBJECT, ARRAY) {
        return ARRAY.reduce((obj, key) => ({ ...obj, [key]: OBJECT[key] }), {});
      },
      RemoveFromArray(ARRAY, SearchIn, VALUE) {
        const removeIndex = ARRAY.findIndex((find) => find[SearchIn] === VALUE);
        return ARRAY.splice(removeIndex, 1);
      },
      SelectFromArray() {
        let original_array = arguments.length ? arguments[0] : [];
        let required_object =
          arguments.length > 1 ? Object.keys(arguments).slice(1) : [];
        let filtered_array = [];
        original_array.forEach((object) => {
          let new_object = required_object.reduce(
            (prevKey, nextKey) =>
              object[arguments[nextKey]]
                ? {
                  ...prevKey,
                  [arguments[nextKey]]: object[arguments[nextKey]],
                }
                : { ...prevKey },
            {}
          );
          filtered_array.push(new_object);
        });
        return filtered_array;
      },
      SelectAsFromArray(original_array = [], required_object = Object) {
        let filtered_array = [];
        original_array.forEach((object) => {
          let new_object = Object;
          Object.keys(required_object).forEach((key) => {
            if (object[required_object[key]])
              new_object[key] = object[required_object[key]];
          });
          filtered_array.push({ ...new_object });
        });
        return filtered_array || [];
      },
      SelectFromObject() {
        let original_object = arguments.length ? arguments[0] : Object;
        let required_keys =
          arguments.length > 1 ? Object.keys(arguments).slice(1) : [];
        let filtered_object = required_keys.reduce(
          (prevKey, nextKey) =>
            original_object[arguments[nextKey]]
              ? {
                ...prevKey,
                [arguments[nextKey]]: original_object[arguments[nextKey]],
              }
              : { ...prevKey },
          {}
        );
        return filtered_object;
      },
      /////////   Calculate pagenation  /////////
      CalculateNumberOfPages(TOTAL, LIMIT) {
        return Math.ceil(TOTAL / LIMIT);
      },
      PaginateArray(ARRAY, PAGE_SIZE, PAGE_NUMBER) {
        return ARRAY.slice(
          (PAGE_NUMBER - 1) * PAGE_SIZE,
          PAGE_NUMBER * PAGE_SIZE
        );
      },
      // Dates
      GetCurrentTime() {
        var time = new Date();
        return time.getHours() + ":" + time.getMinutes();
      },
      GetMonthName(DATE) {
        let date = DATE || this.GetFirstDayOfMonth();
        return i18n.t(new Date(`${date}`).toLocaleString('en-us', { month: 'long' }))
      },
      GetCurrentDate() {
        var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
      },

      GetFirstDayOfMonth() {
        var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        const firstDay = new Date(d.getFullYear(), d.getMonth(), 2);
        return firstDay.toISOString().split("T")[0];
      },
      SubtractDates(FIRST_DATE, SECOND_DATE) {
        var dateFirst = new Date(FIRST_DATE.replace("-", "/"));
        var dateSecond = new Date(SECOND_DATE.replace("-", "/"));
        var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
      },
      SubtractFromDate(DATE, VALUE) {
        const date = new Date(DATE);
        date.setDate(date.getDate() - Number(VALUE));
        return date.toISOString().split("T")[0];
      },
      AddToDate(DATE, VALUE) {
        const date = new Date(DATE);
        date.setDate(date.getDate() + Number(VALUE));
        return date.toISOString().split("T")[0];
      },
      AddMonthsToDate(DATE, VALUE = 1) {
        const date = new Date(DATE);
        var calculated = new Date(date.setMonth(date.getMonth() + VALUE));
        return calculated.toISOString().split("T")[0];
      },
      GetDaysBetween(FIRST_DATE, SECOND_DATE) {
        console.log(FIRST_DATE, SECOND_DATE);
        var getDaysArray = function (start, end) {
          for (
            var arr = [], dt = new Date(start);
            dt <= new Date(end);
            dt.setDate(dt.getDate() + 1)
          ) {
            arr.push(new Date(dt));
          }
          return arr;
        };
        var dayList = getDaysArray(new Date(FIRST_DATE), new Date(SECOND_DATE));
        var dates = dayList.map((v) => v.toISOString().slice(0, 10)).join(",");
        return dates.split(",").map((i) => i);
      },
      GetMonthsBetween(FIRST_DATE, SECOND_DATE) {
        var dateFirst = new Date(FIRST_DATE.replace("-", "/"));
        var dateSecond = new Date(SECOND_DATE.replace("-", "/"));
        var diff = (dateSecond.getTime() - dateFirst.getTime()) / 1000;
        diff /= 60 * 60 * 24 * 7 * 4;
        return Math.abs(Math.round(diff));
      },
      /////////  Numbers /////////
      NumberWithSpaces(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      ConvertToPercentage(PartialValue, TotalValue) {
        return ((100 * PartialValue) / TotalValue).toFixed(2);
      },
      ConvertFromPercentage(Percent, Total) {
        return ((Percent / 100) * Total).toFixed(2);
      },
      CalculatePriceTax(Price, Tax) {
        return {
          price: Number(+Price),
          tax: Number(+Tax),
          priceAfterTax: Number(+Price) + Number(+Price) * Number(+Tax),
          pricePercentage: 100 - 100 * Number(+Tax),
          taxPercentage: 100 * Number(+Tax),
        };
      },
      PriceFormatter(NUMBER) {
        var formatter = new Intl.NumberFormat("ar", {
          style: "currency",
          currency: "LE",
        });

        return formatter.format(NUMBER);
      },
      ARRAY_REDUCE(ITEMS, KEY) {
        return ITEMS.reduce((last_result, active_item) => {
          return +last_result + +active_item[KEY];
        }, 0);
      },
      FILE_TYPE(FILE) {
        // Base64
        console.log(FILE);
        if (String(FILE).substring(0, 5) == "data:") {
          console.log(
            "FILE TYPE =>",
            FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
          );
          switch (FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return "excel";
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            case "video/mp4":
              return "mp4";
            case "video/ogg":
              return "mp4";
            case "video/quicktime":
              return "quicktime";
            case "video/mov":
              return "mov";
            default:
              return "notFound";
          }
        }
        // Normal
        else {
          switch (FILE.type) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return "excel";
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            case "video/mp4":
              return "mp4";
            case "video/ogg":
              return "mp4";
            case "video/quicktime":
              return "quicktime";
            case "video/mov":
              return "mov";
            default:
              var n = FILE.lastIndexOf(".");
              var result = FILE.substring(n + 1);
              return result;
          }
        }
      },
      Currency(NUMBER) {
        return NUMBER.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      },
      NumberWithCommas(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      IncrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            find[ATTRIBUTE] = find[ATTRIBUTE] + 1;
          }
        });
      },
      DecrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            if (find[ATTRIBUTE] > 1) {
              find[ATTRIBUTE] = find[ATTRIBUTE] - 1;
            }
          }
        });
      },
      DecimalFormatter(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      ///////// Decryption / Encryption /////////
      CRYPT(KEY, TEXT) {
        let textEncrypt = Vue.prototype.$CryptoJS.enc.Base64.stringify(
          Vue.prototype.$CryptoJS.enc.Utf8.parse(TEXT)
        );
        return textEncrypt;
      },
      DECRYPT(KEY, ENCODED) {
        let textDecrypt = Vue.prototype.$CryptoJS.enc.Base64.parse(
          ENCODED
        ).toString(Vue.prototype.$CryptoJS.enc.Utf8);
        return textDecrypt;
      },

      sendNotification(TITLE = "GIECO", CONTENT) {
        let OPTIONS = {
          body: i18n.t(CONTENT),
          data: "",
          icon: require("../assets/logo.svg"),
          dir: "rtl",
          // image : require('../assets/maskable_icon_x512.png'),
          // requireInteraction: true,
          silent: false,
          vibrate: [200, 100, 200],
        };
        function checkPermission() {
          return Notification.requestPermission().then(
            (status) => status === "granted"
          );
        }
        function senNotification() {
          checkPermission().then((isAllowed) => {
            if (!isAllowed) {
              return;
            }
            new Notification(i18n.t(TITLE), OPTIONS);
          });
        }
        senNotification();
      },
      ///////// Alert Toast /////////
      ShowAlert(TYPE, TITLE, TOAST = true, MSG, TIMER = 3000) {
        Swal.fire({
          icon: TYPE,
          title: TITLE,
          text: MSG,
          toast: TOAST,
          timer: TIMER,
          position:
            TOAST == true
              ? store.state.Settings.translation.rtl == true
                ? "top-start"
                : "top-end"
              : "center",
          showConfirmButton: false,
        });
      },
      // Cookies
      async SET_COOKIE(COOKIE_NAME, VALUE, EXPIRE = 365, PATH = "/") {
        navigator.cookieEnabled;
        console.log(PATH, EXPIRE);
        try {
          let date = new Date(this.AddToDate(new Date(), EXPIRE));
          date.setTime(date.getTime() + 1 * 60 * 60 * 1000);
          var cache_value =
            escape(VALUE) +
            `; expires=${date.toUTCString()}; SameSite=None; Secure` +
            (PATH ? "" : "; path=" + PATH);
          document.cookie = COOKIE_NAME + "=" + cache_value;
          return true;
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      async GET_COOKIE(NAME) {
        try {
          var i,
            x,
            y,
            ARRcookies = document.cookie.split(";");
          for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x == NAME) {
              return unescape(y);
            }
          }
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      async DELETE_COOKIE(NAME) {
        try {
          document.cookie = `${NAME}=;max-age=0`;
          return true;
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      // EXCEL
      async FILE_TO_JSON(FILE) {
        try {
          var filename = FILE.name;
          var extension = filename
            .substring(filename.lastIndexOf("."))
            .toUpperCase();
          if (extension == ".XLS" || extension == ".XLSX") {
            var reader = new FileReader();
            reader.readAsBinaryString(FILE);
            const excelPromise = new Promise((resolve) => {
              reader.onload = function (e) {
                var data = e.target.result;
                var workbook = XLSX.read(data, {
                  type: "binary",
                });
                var result = {};
                workbook.SheetNames.forEach(function (sheetName) {
                  var sheetData = XLSX.utils.sheet_to_row_object_array(
                    workbook.Sheets[sheetName]
                  );
                  result = sheetData;
                });
                resolve(result);
              };
            });
            return excelPromise;
          } else {
            store.state.snackbarTitle = "please select a valid excel file";
            store.state.snackbarType = "error";
            store.state.snackbarMessages = [];
            store.state.showSnackbar = true;
          }
        } catch (e) {
          console.error(e);
        }
      },
      DOWNLOAD_OFFLINE_EXCEL(HEADER = [], CONTENT = [], FILE_NAME) {
        let active_screen_name = store.state.activeScreenCode
          ? this.FilterPermissions(store.state.activeScreenCode).sub_title
          : "نموزج";
        let excelData = [
          {
            sheet: FILE_NAME || active_screen_name,
            columns: HEADER,
            content: CONTENT,
          },
        ];
        let settings = {
          fileName: FILE_NAME || active_screen_name,
          extraLength: 3,
          writeMode: "writeFile",
          writeOptions: {},
          RTL: store.state.Settings.translation.rtl,
        };
        xlsx(excelData, settings);
      },

    };
  },
};
