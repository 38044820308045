
<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pa-7">
               <v-row align="center">

                  <GenericInput type="file" inputDesign="inline_label" :value="form.image_en"
                     @input="form.image_en = $event" label="home page about us section image" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput dir="rtl" inputDesign="inline_label" type="file" :value="form.image_ar"
                     @input="form.image_ar = $event" label="صوره عن الغرابلي في صفحه الرئيسيه" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="texteditor" rowsNumber="9" :value="form.our_vision_en"
                     @input="form.our_vision_en = $event" label="GIECO vision" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput dir="rtl" type="texteditor" rowsNumber="9" :value="form.our_vision_ar"
                     @input="form.our_vision_ar = $event" label="رؤيه GIECO" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="texteditor" rowsNumber="9" :value="form.our_goals_en"
                     @input="form.our_goals_en = $event" label="GIECO goals" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput dir="rtl" type="texteditor" rowsNumber="9" :value="form.our_goals_ar"
                     @input="form.our_goals_ar = $event" label="هدف GIECO" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="texteditor" rowsNumber="9" :value="form.our_mission_en"
                     @input="form.our_mission_en = $event" label="GIECO mission" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput dir="rtl" type="texteditor" rowsNumber="9" :value="form.our_mission_ar"
                     @input="form.our_mission_ar = $event" label="مهمه GIECO" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="texteditor" rowsNumber="9" :value="form.our_history_en"
                     @input="form.our_history_en = $event" label="GIECO history" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput dir="rtl" type="texteditor" rowsNumber="9" :value="form.our_history_ar"
                     @input="form.our_history_ar = $event" label="تاريخ GIECO" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />


               </v-row>

            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import { ControlMixins } from '@/mixins/ControlMixins';
export default {
   name: "AboutUSControlControl",
   mixins: [ControlMixins],
   data: () => ({
      pageData: {
         screen_code: "01-010",
         endpoint: 'About',
         url: null,
         controlRoute: "/about-us/",
         entityName: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      form: {
         image_ar: null,
         image_en: null,
         our_vision_ar: null,
         our_vision_en: null,
         our_mission_ar: null,
         our_mission_en: null,
         our_goals_ar: null,
         our_goals_en: null,
         our_history_ar: null,
         our_history_en: null,
      },
   }),
   components: {

   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      getData() {
         this.pageData.isEdit = true
         this.pageData.isLoading = true;
         this.$api.GET_METHOD(`${this.pageData.show_endpoint}/1`).then((response) => {
            this.pageData.editIsLoading = false;
            this.pageData.isLoading = false;
            if (response.status) {
               console.log("response", response);
               this.form = response.data;
               // this.$refs.form.validate();
            }
         })
      }
   },
};
</script>
