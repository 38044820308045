
<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 py-7">
               <v-row align="center" justify="space-around">
                  <v-col cols="12" md="6" lg="3">
                     <AvatarProfile :width="200" :height="200" :img="form.image"
                        :useServerUrl="String(form.image).substring(0, 7) == 'uploads'" :callBackMethod="updateImage"
                        :base64="false" />
                  </v-col>
                  <v-col cols="auto">
                     <v-divider style="height: 240px;" vertical />
                  </v-col>
                  <v-col cols="12" md="6" lg="8">
                     <v-row align="center">
                        <GenericInput type="text" :value="form.name" @input="form.name = $event" label="name"
                           :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]" />

                        <GenericInput type="text" :value="form.email" @input="form.email = $event" name="username" label="username"
                           :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]"
                           :validation_type="username_rule" hint="don't use spaces in username or any symbol" />

                        <GenericInput type="mobile" name="mobile" :value="form.mobile" @input="form.mobile = $event" label="mobile"
                           :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]" />

                        <!-- <GenericInput type="select" :disabled="true" :lookups="userTypes" :value="form.userType"
                           @input="form.userType = $event" label="user type" :multi="false" :required="true"
                           selected_label="name" selected_prop="name" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 6]" /> -->

                        <GenericInput type="password"  :value="form.password"
                           @input="form.password = $event" :label="pageData.isEdit? 'new password' : 'password'" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]" />



                     </v-row>
                  </v-col>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import { ControlMixins } from '@/mixins/ControlMixins';
export default {
   name: "UsersControl",
   mixins: [ControlMixins],
   data: () => ({
      pageData: {
         screen_code: "01-002",
         endpoint: 'User',

         url: null,
         controlRoute: "/user-control/",
         entityName: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      userTypes: [],
      username_rule: [],
      form: {
         name: null,
         mobile: null,
         email: null,
         password: null,
         image: null,
         userType: 'Admin',
      },
   }),
   components: {

   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.getData();
      this.username_rule = [
         (v) => !!(v || "").match(/^\S*$/) || this.$i18n.t("username not valid"),
      ];
      this.userTypes = [
         {
            name: 'Admin',
         },
      ]
   },
   methods: {
      updateImage(img) {
         this.form.image = img;
      },
   },
};
</script>
