<template>
  <v-container fluid class="backgroundW " id="appBar">
    <v-row justify="space-between" align="center">
      <v-col cols="auto">
        <v-row align="center">
          <v-col cols="auto" class="py-0">
            <div class="subtitle-1 font-weight-bold"> {{ greeting }}  
              <span class="primary--text">{{ $store.state.userData.name }}</span>
              <!-- <span class="primary--text">{{ $t('GIECO') }}</span> -->
            </div>
          </v-col>
          <v-col cols="auto" class="py-0">
            <SearchInput />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="auto" class="py-0">
        <v-row align="center" justify="end">
          <!-- <v-col cols="3" md="auto" sm="auto">
            <v-btn v-on:click="changeLanguage" small fab elevation="0">
              <v-icon v-if="$vuetify.rtl">mdi-alphabetical-variant</v-icon>
              <v-icon v-else>mdi-abjad-arabic</v-icon>
            </v-btn>
          </v-col> -->
          <v-col cols="3" md="auto" sm="auto">
            <v-btn small fab @click="darkTheme" depressed>
              <v-icon color="yellow1" v-if="$store.state.Settings.darkTheme">mdi-white-balance-sunny</v-icon>
              <v-icon v-if="!$store.state.Settings.darkTheme">mdi-weather-night</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3" md="auto" sm="auto">
            <v-btn small fab elevation="0" v-fullscreen="options">
              <v-icon v-if="!fullscreen">mdi-fullscreen</v-icon>
              <v-icon v-if="fullscreen">mdi-fullscreen-exit</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="3" md="auto" sm="auto">
            <UserMenu></UserMenu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserMenu from "./UserMenu.vue";
import SearchInput from "./SearchInput.vue";
export default {
  name: "Appbar",
  computed: {
    options() {
      return {
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen;
        },
        target: ".directive-fullscreen-wrapper",
        pageOnly: this.pageOnly,
        teleport: this.teleport,
      };
    },
  },
  data() {
    return {
      fullscreen: false,
      greeting: '',
    };
  },
  components: {
    UserMenu,
    SearchInput,
  },
  mounted() {
    this.setGreeting();
  },
  methods: {
    darkTheme() {
      this.$store.dispatch("Settings/darkTheme");
    },
    toggleSidebar() {
      this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
    setGreeting() {
      const time = new Date().getHours();

      if (time >= 0 && time < 5) {
        this.greeting = "Good Night 😴";
      }
      else if (time >= 6 && time <= 11) {
        this.greeting = "Good Morning ⛅️";
      }
      else if (time >= 12 && time <= 17) {
        this.greeting = "Good Afternoon ☀️";
      }
      else {
        this.greeting = "Good Evening 🌙";
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#appBar {
  position: relative;
  height: 60px;
  z-index: 55;
  width: 100vw !important;
  left: 0 !important;
  right: 0 !important;
  display: flex;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}
</style>