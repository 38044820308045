<template>
   <v-menu top v-model="menu"  :open-on-hover="openOnHover" :rounded="roundedMenu"
      :bottom="bottom" :offset-y="offsetY" :offset-x="offsetX" :nudge-width="200" :transition="transition">
      <template v-slot:activator="{ on, attrs }">
         <v-btn depressed :color="color" :icon="icon" :class="buttonClass" :disabled="disableButton" v-bind="attrs"
            v-on="on">
            <span v-if="!buttonSlot">{{ $t(buttonText) }}</span>
         </v-btn>
      </template>

      <v-card :class="contentClass">
         <span v-if="!contentSlot">
            <div :class="titleClass" class="text-capitalize" v-if="contentTitle"> {{ $t(contentTitle) }}</div>
            <div :class="bodyClass" v-if="contentBody"> {{ $t(contentBody) }}</div>
         </span>

         <v-card-actions>
            <v-spacer />
            <v-btn color="error" text @click="()=>{menu = false; declineMethod()}">
               {{ conditional ? $t('no') : $t('cancel') }}
            </v-btn>
            <v-btn color="success" depressed @click="()=>{menu = true; approveMethod()}">
               {{ conditional ? $t('yes') : $t('save') }}
            </v-btn>
         </v-card-actions>
      </v-card>
   </v-menu>
</template>


<script>

export default {
   name: "Popover",
   components: {},
   props: {
      closeOnClick: { default: false },
      transition: { default: 'slide-y-transition' },
      top: { default: false },
      left: { default: false },
      right: { default: false },
      bottom: { default: true },
      openOnHover: { default: false },
      roundedMenu: { default: false },
      offsetY: { default: true },
      offsetX: { default: true },
      color: { default: 'primary' },
      icon: { default: false },
      buttonText: { default: 'button' },
      buttonSlot: { default: false },
      disableButton: { default: false },
      buttonClass: { default: null },
      contentClass: { default: 'pa-5 shadow-none' },
      contentSlot: { default: false },
      contentTitle: { default: 'are you sure?' },
      titleClass: { default: 'subtitle-1  gray2--text' },
      contentBody: { default: null },
      bodyClass: { default: 'body-2 gray8--text' },
      conditional: { default: true },
      declineMethod: { default: Function },
      approveMethod: { default: Function },
   },
   mounted() {

   },
   data: () => ({
      menu: false,
   }),
   methods: {

   },


}
</script>
<style scoped></style>