<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :option="pageData.options" :data="pageData.rows"
            :header="tableHeader" :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteValueMethod"
            :toggleActiveMethod="toggleActiveMethod">
            <template v-slot:td="{ row, header, index }">
              <div v-if="header.key == 'contactStatus'">
                <v-chip depressed @click="contact(row.id, index)" small
                  :color="row.contactStatus == 'Answered' ? 'success' : 'yellow2'">
                  {{ row.contactStatus == 'Answered' ? $t('contact done') : $t('is contact?') }}
                </v-chip>
              </div>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { EntityMixins } from "@/mixins/EntityMixins";
export default {
  name: "ContactRequestsEntity",
  mixins: [EntityMixins],
  data: () => ({
    pageData: {
      screen_code: "01-007",
      endpoint: null,
      list_key: 'items',
      response: {},
      controlRoute: "contact-control",
      url: null,
      entityName: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: new URLSearchParams(),
    },
  }),
  components: {},
  computed: {
    tableHeader() {
      let header = [
        { text: "#", key: "id", type: 'text', classes: "" },
        { text: "name", key: "name", type: 'text', classes: "" },
        { text: "email", key: "email", type: 'text', classes: "" },
        { text: "mobile", key: "mobile", type: 'text', classes: "" },
        { text: "message", key: "message", type: 'text', classes: "" },
        { text: "created at", key: "createdAt", type: 'date', classes: "" },
        { text: "updated by", key: "updatedBy", type: 'text', classes: "" },
        { text: "updated at", key: "updatedAt", type: 'date', classes: "" },
        { text: "contact status", key: "contactStatus", type: 'slot', classes: "" },
      ]
      if (this.$store.getters.user.superAdmin) {
        header.push({ text: "actions", key: "id", type: 'actions', classes: "" })
      }
      return header
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.pageData.options.create = false;
    this.pageData.options.edit = false;
    this.pageData.options.view = false;
    this.getData();
  },
  methods: {
    contact(ID, INDEX) {
      this.pageData.rows[INDEX].isLoading = true;
      let status = this.pageData.rows[INDEX].contactStatus == 'Answered' ? 'Pending' : 'Answered'
      this.$api.PUT_METHOD(`${this.pageData.endpoint}/${ID}`, {contactStatus : status}).then((response) => {
        this.pageData.rows[INDEX].isLoading = false;
        if (response.status) {
          this.pageData.rows[INDEX].contactStatus = status;
          this.pageData.rows[INDEX].updatedBy = this.$store.getters.user.email;
          this.pageData.rows = [...this.pageData.rows]
        }
      })
    }
  },
};
</script>
