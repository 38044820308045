
<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pb-7">
               <v-tabs v-model="tab">
                  <v-tab class="text-capitalize">{{ $t('blog basic data') }}</v-tab>
                  <v-tab class="text-capitalize">{{ $t('search engine optimization') }} (SEO)</v-tab>
               </v-tabs>
               <v-tabs-items v-model="tab">

                  <v-tab-item class="ma-5">
                     <v-row align="center">
                        <GenericInput type="text" inputDesign="inline_label" :value="form.title_en"
                           @input="form.title_en = $event" label="blog title" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput dir="rtl" inputDesign="inline_label" type="text" :value="form.title_ar"
                           @input="form.title_ar = $event" label="عنوان المقاله" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="text" inputDesign="inline_label" :value="form.author_en"
                           @input="form.author_en = $event" label="author" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput dir="rtl" inputDesign="inline_label" type="text" :value="form.author_ar"
                           @input="form.author_ar = $event" label="مؤلف" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="file" inputDesign="inline_label" :value="form.image_en"
                           @input="form.image_en = $event" label="blog image" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />


                        <GenericInput dir="rtl" inputDesign="inline_label" type="file" :value="form.image_ar"
                           @input="form.image_ar = $event" label="صوره المقاله" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="texteditor" :value="form.description_en" @input="form.description_en = $event"
                           label="blog details" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput dir="rtl" type="texteditor" :value="form.description_ar"
                           @input="form.description_ar = $event" label="تفاصيل المقاله" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput type="select" :lookups="categories" :value="form.category"
                           @input="form.category = $event" label="blog category" :multi="false" :required="true"
                           :selected_label="`name_${$i18n.locale}`" selected_prop="id" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]" :has_slot="true">
                           <template v-slot:append>
                              <v-btn depressed small class="lockupsAddButton" @click="categoryDialog = true"
                                 color="success">
                                 <v-icon color="white" left>mdi-plus</v-icon> {{ $t('add new category') }}
                              </v-btn>
                           </template>
                           <template v-slot:selection="item">
                              <div class="subtitle-2">
                                 <div>{{ item.item[`name_${$i18n.locale}`] }}</div>
                              </div>
                           </template>
                           <template v-slot:item="item">
                              <template>
                                 <div class="body-2 w-100 d-flex align-center justify-space-between">
                                    <div>{{ item.item[`name_${$i18n.locale}`] }}</div>
                                    <v-btn depressed @click="() => { categoryDialog = true; editCategory = item.item }"
                                       icon color="info" small><v-icon size="16">mdi-pencil-box</v-icon></v-btn>
                                 </div>
                              </template>
                           </template>
                        </GenericInput>

                     </v-row>
                  </v-tab-item>

                  <v-tab-item class="ma-5">
                     <v-row align="center">
                        <GenericInput type="text" inputDesign="inline_label" :value="form.seo_title_en"
                           @input="form.seo_title_en = $event" label="project title in SEO" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                        <GenericInput dir="rtl" inputDesign="inline_label" type="text" :value="form.seo_title_ar"
                           @input="form.seo_title_ar = $event" label="عنوان المشروع في SEO" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />


                        <GenericInput type="textarea" inputDesign="inline_label" rowsNumber="9"
                           :value="form.seo_description_en" @input="form.seo_description_en = $event"
                           label="project description in SEO" :required="false" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]" />

                        <GenericInput dir="rtl" inputDesign="inline_label" type="textarea" rowsNumber="9"
                           :value="form.seo_description_ar" @input="form.seo_description_ar = $event"
                           label="تفاصيل المشروع في SEO" :required="false" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 6]" />

                        <GenericInput type="combobox" inputDesign="inline_label" :value="form.seo_keywords"
                           @input="form.seo_keywords = $event" label="SEO keywords" :required="false"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />


                     </v-row>
                  </v-tab-item>
               </v-tabs-items>

            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
      <AddLockupsDialog entityName="Category" endPoint="Category" :enablePhone="true" :sendAsRequest="true"
         :editForm="editCategory" :dialog="categoryDialog" :closeMethod="() => { categoryDialog = false }"
         :saveMethod="saveCategory" />
   </section>
</template>



<script>
import { ControlMixins } from '@/mixins/ControlMixins';
export default {
   name: "Blog&NewsControl",
   mixins: [ControlMixins],
   data: () => ({
      pageData: {
         screen_code: "01-005",
         endpoint: 'Blog',
         url: null,
         controlRoute: "/blogs-news-control/",
         entityName: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      tab: 0,
      valid: false,
      categoryLoading: false,
      categoryDialog: false,
      categories: [],
      editCategory: null,
      form: {
         title_ar: '',
         title_en: '',
         author_ar: '',
         author_en: '',
         image_ar: '',
         image_en: '',
         description_ar: '',
         description_en: '',
         category: '',
         // 
         seo_title_en: '',
         seo_title_ar: '',
         seo_description_en: '',
         seo_description_ar: '',
         seo_keywords: '',
      },
   }),
   watch: {
      categoryDialog() {
         if (!this.categoryDialog) {
            this.editCategory = null;
         }
      },
      'form.category'() {
         if(typeof this.form.category == 'object'){
            this.form.category = this.form.category.id
         }
      }
   },
   components: {

   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.getData();
      this.getCategory();
   },
   methods: {
      getCategory() {
         this.categoryLoading = true;
         this.$api.GET_METHOD(`Category/allCategoriesOnly`).then((response) => {
            this.categoryLoading = false;
            if (response.status) {
               this.categories = response.data || [];
            }
         })
      },
      saveCategory(category) {
         if (category.isEdit) {
            category.isEdit = false;
            const index = this.categories.findIndex((cat) => cat.id == category.id);
            this.categories[index] = category;
            this.categories = [...this.categories];
         } else {
            this.categories.push(category);
         }
         this.form.category = category.id;
         this.categoryDialog = false;
      }
   },
};
</script>
