<template>
  <div class="text-center" id="appBarMenu">
    <v-menu :close-on-content-click="true" offset-y transition="slide-y-transition" bottom>
      <template v-slot:activator="{ on, attrs }">
        <!-- <v-btn v-bind="attrs" v-on="on" text  class="overflow-hidden" > -->

        <div v-bind="attrs" v-on="on" class="d-flex align-center">
          <v-btn small fab elevation="0">

            <img v-if="$store.state.userData.image" :src="$api.serverUrl + $store.state.userData.image"
              :alt="$store.state.userData.name" />
            <v-icon size="28" v-else>mdi-account-outline</v-icon>
          </v-btn>
          <div class="text-start">
            <h5 class="text--disabled d-none body-2 d-lg-block mx-2">{{ $store.state.userData.title }}</h5>
          </div>

        </div>

        <!-- </v-btn> -->
      </template>

      <v-card width="300" class="py-0 mt-5 rounded-lg">
        <v-list-item-content class="justify-center">

          <div class="userList d-flex justify-space-between px-4 align-center text-center"
          @click="$router.push(`/users-control/${$store.getters.user.id}`)">
            <v-avatar color="transparent" size="30">
              <img v-if="$store.state.userData.image" :src="$api.serverUrl + $store.state.userData.image"
                :alt="$store.state.userData.name" />
              <v-icon size="28" v-else>mdi-account-circle-outline</v-icon>
            </v-avatar>
            <div style="width: 85%;" class="text-start">
              <h5 class="mx-2 text-capitalize my-1">{{ $store.state.userData.name }}</h5>
              <h5 class="mx-2 text-capitalize my-1 text--disabled">{{ $store.state.userData.email }}</h5>
            </div>
          </div>
          <div>
            <!-- <v-divider class="my-2"></v-divider>
            <div class="userList d-flex justify-space-between px-4 align-center text-center"
              @click="changePassDialog = true">
              <v-icon size="28">mdi-shield-key-outline</v-icon>
              <div style="width: 85%;" class="text-start">
                <h5 class="mx-2 text-capitalize my-1">{{ $t("change password") }}</h5>
              </div>
            </div> -->

            <v-divider class="my-2"></v-divider>
            <div class="userList d-flex justify-space-between px-4 align-center text-center" @click="$api.LOGOUT(true)">
              <v-icon size="28" color="error">mdi-logout</v-icon>
              <div style="width: 85%;" class="text-start">
                <h5 class="mx-2 error--text text-capitalize my-1">{{ $t("logout") }}</h5>
              </div>
            </div>
          </div>
          <ChangePasswordModalVue :dialog="changePassDialog" :backValueMethod="close"></ChangePasswordModalVue>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </div>
</template>
<style scoped>
.v-menu__content {
  box-shadow: 0px 21px 32px rgba(0, 0, 0, 0.05) !important;
  top: 3rem !important;
  border-radius: 0px 0px 8px 8px;
}

#appBarMenu .v-menu__content {
  top: 64px !important;
  box-shadow: none !important;
}

#appBarMenu.v-menu__content .v-card {
  background-color: #fafafa !important;
}

.userList {
  cursor: pointer;
}
</style>

<script>
import ChangePasswordModalVue from "../modals/ChangePasswordModal.vue";
export default {
  name: "UserMenu",
  computed: {

  },
  components: { ChangePasswordModalVue },
  data: () => ({
    user: {},
    login_date: null,
    changePassDialog: false,
  }),
  mounted() {
    this.login_date = localStorage.getItem("login_date");
  },

  methods: {
    close(status) {
      this.changePassDialog = status;
    },
  },
};
</script>
