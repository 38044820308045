
<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pa-7">
               <v-row align="center">

                  <GenericInput type="text" inputDesign="inline_label" :value="form.name_en"
                     @input="form.name_en = $event" label="section title" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="text" inputDesign="inline_label" :value="form.name_ar"
                     @input="form.name_ar = $event" label="عنوان القسم" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="file" inputDesign="inline_label" :value="form.image"
                     @input="form.image = $event" label="background image" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="file" inputDesign="inline_label" :value="form.video"
                     @input="form.video = $event" label="background video" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />


                  <GenericInput type="textarea" inputDesign="inline_label" rowsNumber="9" :value="form.description_en"
                     @input="form.description_en = $event" label="section description" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput dir="rtl" inputDesign="inline_label" type="textarea" rowsNumber="9" :value="form.description_ar"
                     @input="form.description_ar = $event" label="تفاصيل القسم" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

                  <GenericInput type="select"  :lookups="background_types" :value="form.background_type"
                     @input="form.background_type = $event" label="background type" :multi="false" :required="true" selected_label="name"
                     selected_prop="name" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />


               </v-row>

            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import { ControlMixins } from '@/mixins/ControlMixins';
export default {
   name: "LandingSectionControl",
   mixins: [ControlMixins],
   data: () => ({
      pageData: {
         screen_code: "01-009",
         endpoint: 'HeroSection',
         url: null,
         controlRoute: "/landing-section/",
         entityName: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      background_types: [{ name: 'Image' }, { name: 'Video' }],
      form: {
         background_type: 'video',
         video: null,
         image: null,
         name_en: null,
         name_ar: null,
         description_ar: null,
         description_en: null,
      },
   }),
   components: {

   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      getData() {
         this.pageData.isEdit = true
         this.pageData.isLoading = true;
         this.$api.GET_METHOD(`${this.pageData.show_endpoint}/1`).then((response) => {
            this.pageData.editIsLoading = false;
            this.pageData.isLoading = false;
            if (response.status) {
               console.log("response", response);
               this.form = response.data;
               // this.$refs.form.validate();
            }
         })
      }
   },
};
</script>
