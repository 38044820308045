import Vue from "vue";
import Vuex from "vuex";
import Settings from "./Settings";
import router from "../router";
import i18n from "../plugins/i18n";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // APP Settings
    app_version: process.env.VUE_APP_VERSION, //last app update Year.Month.Day.UploadNumber
    splashScreen: false,
    offline: false,
    sidebarIsOpen: false,
    endpointURL: "https://backend.gieco.vision.studio/", // Live
    // endpointURL: "http://192.168.1.3:3500/", // Testing
    // endpointURL: "http://localhost:4000/", // Testing
    showSnackbar: false,
    snackbarType: 'info',
    snackbarTime: 4500,
    snackbarTitle: null,
    snackbarMessages: [],
    requestLoader: {
      isLoading: false,
      isDialog: true,
      loadingMessage: 'is loading',
      successMessage: 'loading done',
      errorMessage: null,
    },
    isLoading: false,
    apiAccess:
      "xcVBybDQhtDRqPxFUkpe1JVsZqyGGBR1v1KqendQ2qcLbqXB9hgqin41uRJ3qGTwD0L1hmRS29a0gXbKf8EBVcBy7ofvhKviDzJ88u8p6Hs9mNncXZSXOxeSQ7Ka7tlEhCnBMFtVSgZbuYf9sBubou5pWBxu1okFMC2ksCfk3jxdTQmeHq5xBlOL3pmkqq32L26LX1EB",
    // route settings
    route: null,
    pageTitle: null,
    activeScreenCode: null,
    prevScreen: {
      screen_code: null,
      permission: null,
      main_id: null,
      main_title: null,
      cat_id: null,
      cat_title: null,
      sub_id: null,
      sub_title: null,
      routeName: null,
      url: null,
      params: {
        page: 1,
        rows: 15,
        search: "",
      },
      queryParams: {
        page: 1,
        rows: 15,
        active: 1,
        search: "",
      },
      requestParam: `?`,
    },
    activeScreen: {
      screen_code: null,
      permission: null,
      main_id: null,
      main_title: null,
      cat_id: null,
      cat_title: null,
      sub_id: null,
      sub_title: null,
      routeName: null,
      url: null,
      params: {
        page: 1,
        pageSize: 15,
        search: "",
      },
      queryParams: {
        page: 1,
        pageSize: 15,
        active: 1,
        search: "",
      },
      requestParam: `?`,
    },
    screens: [],
    // user data
    lastUpdate: localStorage.getItem("lastUpdate")
      ? localStorage.getItem("lastUpdate")
      : " ",
    accessToken: null,
    isAuth: false,
    userData: {},
  },
  mutations: {
    SET_ROUTE(state, route) {
      console.log(router.currentRoute.query , 'query');
      state.route = route;
      state.activeScreenCode = route.meta.screen_code;
      document.title = i18n.t("GIECO - ") + i18n.t(route.meta.name);
      route.meta.queryParams = {
        page: route.query.page || 1,
        pageSize: route.query.pageSize || 15,
        active: route.query.active || 1,
        search: route.query.search || '',
      };
      state.activeScreen = route.meta || null;
    },

    UPDATE_ACTIVE_SCREEN(state, activeScreen ) {
      state.activeScreen = activeScreen;
      state.pageTitle = activeScreen.sub_title;
    },
    UPDATE_CLIENT(state, id) {
      state.clientID = id;
      axios.defaults.headers.common["clientID"] = id;
    },
    RESET_REQUEST_LOADER(state) {
      state.requestLoader = {
        isLoading: false,
        isDialog: true,
        loadingMessage: 'is loading',
        successMessage: 'loading done',
        errorMessage: null,
      }
    }
  },
  getters: {
    activeScreen(state) {
      return state.activeScreen;
    },
    prevScreen(state) {
      return state.prevScreen;
    },
    user(state) {
      return state.userData;
    },
  },
  actions: {
    checkAppVersion() {
      localStorage.setItem("app_version", process.env.VUE_APP_VERSION);
    },
    checkAuth({ state }) {
      state.isAuth = localStorage.getItem(
        Vue.prototype.$global.CRYPT("gieco", "access-token")
      )
        ? true
        : false;
      if (!state.isAuth) {
        router.push("/login").catch(() => { });
      } else {
        state.accessToken = Vue.prototype.$global.DECRYPT("gieco",
          localStorage.getItem(
            Vue.prototype.$global.CRYPT("gieco", "access-token")
          )
        );
        state.userData = JSON.parse(
          Vue.prototype.$global.DECRYPT(
            "gieco",
            localStorage.getItem(
              Vue.prototype.$global.CRYPT("gieco", "user")
            )
          )
        );
        axios.defaults.headers.common["Authorization"] = "Bearer " + state.accessToken;
      }
    },
    getUserData({ state }) {
      state.splashScreen = true;

      Vue.prototype.$api.GET_METHOD(`get_my_profile`).then((response) => {
        state.splashScreen = false;
        if (response.check) {
          Vue.prototype.$api.SET_AUTH(response.data);
        }
      });
    },
    setPagesRows({ state }, data) {
      state.activeScreen.pageNumber = data.pageNumber;
      state.activeScreen.rowsNumber = data.rowsNumber;
    },
  },

  modules: { Settings },
});
